<template>
  <v-app-bar app height="56" class="layout-chat white px-2" elevation="0">
    <v-container class="layout-chat__container d-flex align-center pa-0">
      <v-btn
        class="rounded-md pa-0"
        :to="chatListRoute"
        color="secondary"
        min-width="40"
        width="40"
        height="40"
        elevation="0"
      >
        <v-icon color="primary">
          mdi-arrow-left
        </v-icon>
      </v-btn>

      <span class="text-h6 pl-2">{{ clientName }}</span>

      <v-spacer />

      <v-menu content-class="layout-chat__menu" rounded="0" transition="slide-y-transition" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon height="24" width="24" v-bind="attrs" v-on="on">
            <v-icon color="primary" size="20">mdi-information</v-icon>
          </v-btn>
        </template>

        <v-list v-for="unit in units" :key="unit.id" class="layout-chat__unit-item py-1">
          <v-list-item class="layout-chat__field py-1 primary--text">
            <span class="layout-chat__field-name text-body-2 flex-grow">
              {{ $t('label.project') }}
            </span>
            <v-list-item-title class="text-body-2 font-weight-medium ml-2">
              {{ unit.project.name }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item class="layout-chat__field py-1 primary--text">
            <span class="layout-chat__field-name text-body-2 flex-grow">
              {{ $t('label.building') }}
            </span>
            <v-list-item-title class="text-body-2 font-weight-medium ml-2">
              {{ unit.building.name }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item class="layout-chat__field py-1 primary--text">
            <span class="layout-chat__field-name text-body-2 flex-grow">
              {{ $t('label.unit') }}
            </span>
            <v-list-item-title class="text-body-2 font-weight-medium ml-2">
              {{ unit.unit.name }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item v-if="unit.room" class="layout-chat__field py-1 primary--text">
            <span class="layout-chat__field-name text-body-2 flex-grow">
              {{ $t('label.room') }}
            </span>
            <v-list-item-title class="text-body-2 font-weight-medium ml-2">
              {{ unit.room.name }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-container>
  </v-app-bar>
</template>

<script>
// node_modules
import { mapGetters, mapState } from 'vuex';

// Constants
import { CHAT } from '@/constants/routes';
import { COMPANY_SECTIONS_RIGHTS } from '@/store/modules/user/types';

// Utils
import { flushPromises } from '@/utils/scheduler';

// Http
import client from '@/http/client';

// Services
import usersService from '@/services/users';

export default {
  name: 'LayoutChat',

  data() {
    return {
      units: [],
      isLoading: false,
    };
  },

  computed: {
    ...mapState({
      chats: state => state.chat.employeeRoomList,
      selectedChat: state => state.chat.selectedChat,
    }),

    selectedChatId() {
      return +this.$route.query.id || -1;
    },

    clientId() {
      return this.selectedChat?.user?.id;
    },

    clientName() {
      return this.selectedChat?.user?.name;
    },

    chatListRoute() {
      return { name: CHAT };
    },

    ...mapGetters('user', {
      companySections: COMPANY_SECTIONS_RIGHTS,
    }),

    accessToRooms() {
      return !!this.companySections.find(section => section.name === 'rooms');
    },
  },

  watch: {
    clientId: {
      handler() {
        this.fetchUnits();
      },
      immediate: true,
    },
  },

  methods: {
    async fetchUnits() {
      if (!this.clientId) return;

      if (this.$options.cancelFetchUnits) {
        this.$options.cancelFetchUnits();
        await flushPromises();
      }

      this.isLoading = true;

      const cancelSource = client.getCancelToken();
      this.$options.cancelFetchUnits = cancelSource.cancel;

      usersService
        .getClientById(this.clientId)
        .then(({ usages }) => {
          this.units = usages.map(usage => {
            const { unit } = usage;
            return {
              project: usage.project,
              building: usage.building,
              unit: unit.parentUnit
                ? { id: unit.parentUnit, name: unit.parentUnitName }
                : { id: unit.id, name: unit.name },
              room: unit.parentUnit && this.accessToRooms ? { id: unit.id, name: unit.name } : null,
            };
          });
        })
        .finally(() => {
          this.isLoading = false;
          this.$options.cancelFetchUnits = null;
        });
    },
  },

  cancelFetchUnits: null,
};
</script>

<style lang="scss">
$header-height-and-bottom-padding: 80px;

.layout-chat {
  &__container {
    margin: 0 -16px;
    width: calc(100% + 32px);
  }

  &__field-name {
    opacity: 0.5;
  }

  &__menu {
    top: 56px !important;
    left: 0 !important;
    min-width: 100vw !important;
    box-shadow: none !important;
    max-height: calc(100vh - #{$header-height-and-bottom-padding});
    overflow: auto;
  }

  &__field {
    min-height: 22px !important;
  }

  &__unit-item {
    background: $--blue-color-60 !important;
    border-top: 1px solid $--blue-color-40 !important;
  }
}
</style>
