<template>
  <div class="projects-layout">
    <info-modal v-model="modal.show" :title="modal.message" @close="closeModal" />
    <div class="d-flex flex-row align-center justify-space-between">
      <div>
        <router-link
          class="projects-layout__link text-h5 text-md-h4 font-weight-bold mr-2"
          active-class="projects-layout__link--active"
          exact-path
          :to="projectsLink"
          >{{ $t('projects.projects') }}</router-link
        >
        <router-link
          class="projects-layout__link text-h5 text-md-h4 font-weight-bold ml-4"
          active-class="projects-layout__link--active"
          :to="archiveLink"
          >{{ $t('projects.archive') }}</router-link
        >
      </div>

      <div v-if="isProjectsPage">
        <div v-if="media.isMobile">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <icon-button v-bind="attrs" v-on="on">
                <v-icon>
                  mdi-dots-horizontal
                </v-icon>
              </icon-button>
            </template>

            <v-list>
              <v-list-item v-if="canUpdateProjects" :to="projectRoute">
                <v-list-item-title>
                  <v-icon right>mdi-plus</v-icon>
                  {{ $t('button.add_project') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="canUpdateProjects" :to="buildingRoute">
                <v-list-item-title>
                  <v-icon right>mdi-plus</v-icon>
                  {{ $t('button.add_building') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="canUpdateProjects" :to="unitRoute">
                <v-list-item-title>
                  <v-icon right>mdi-plus</v-icon>
                  {{ $t('button.add_unit') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="canUpdateProjects && accessToRooms" :to="roomRoute">
                <v-list-item-title>
                  <v-icon right>mdi-plus</v-icon>
                  {{ $t('button.add_room') }}
                </v-list-item-title>
              </v-list-item>

              <v-list-item @click="exportProject">
                <v-list-item-title>
                  <v-icon right>mdi-file-download</v-icon>
                  {{ $t('button.export') }}
                </v-list-item-title>
              </v-list-item>

              <file-input v-if="canUpdateProjects" v-slot="{ on, attrs }" @input="importProject">
                <!--
                    @click.prevent необходим для избежания двойного вызова окна загрузки input'а,
                    @click.stop не позволяет добиться этого поведения
                  -->
                <v-list-item :disabled="isProcessing" v-bind="attrs" v-on="on" @click.prevent>
                  <v-list-item-title>
                    <v-icon right>mdi-file-upload</v-icon>
                    {{ $t('button.import') }}
                  </v-list-item-title>
                </v-list-item>
              </file-input>
            </v-list>
          </v-menu>
        </div>

        <div v-else>
          <v-menu v-if="canUpdateProjects" offset-y left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" class="mr-3" large elevation="0" v-bind="attrs" v-on="on">
                {{ $t('button.add') }}
                <v-icon right>mdi-plus</v-icon>
              </v-btn>
            </template>

            <v-list min-width="250">
              <v-list-item color="primary" :to="projectRoute">
                <v-list-item-title>{{ $t('button.add_project') }}</v-list-item-title>
              </v-list-item>

              <v-list-item color="primary" :to="buildingRoute">
                <v-list-item-title>{{ $t('button.add_building') }}</v-list-item-title>
              </v-list-item>

              <v-list-item color="primary" :to="unitRoute">
                <v-list-item-title>{{ $t('button.add_unit') }}</v-list-item-title>
              </v-list-item>

              <v-list-item v-if="accessToRooms" color="primary" :to="roomRoute">
                <v-list-item-title>{{ $t('button.add_room') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn color="secondary" class="primary--text mr-3" large elevation="0" @click="exportProject">
            {{ $t('button.export') }}
            <v-icon right>mdi-file-download</v-icon>
          </v-btn>
          <file-input v-if="canUpdateProjects" v-slot="{ on, attrs }" @input="importProject">
            <v-btn
              color="secondary"
              :disabled="isProcessing"
              large
              class="primary--text"
              elevation="0"
              v-bind="attrs"
              v-on="on"
            >
              {{ $t('button.import') }}
              <v-icon right>mdi-file-upload</v-icon>
            </v-btn>
          </file-input>
        </div>
      </div>

      <template v-else>
        <v-menu v-if="media.isMobile" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <icon-button v-bind="attrs" v-on="on">
              <v-icon>
                mdi-dots-horizontal
              </v-icon>
            </icon-button>
          </template>

          <v-list>
            <v-list-item @click="exportProject">
              <v-list-item-title>
                <v-icon right>mdi-file-download</v-icon>
                {{ $t('button.export') }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn v-else color="secondary" large class="primary--text" elevation="0" @click="exportProject">
          {{ $t('button.export') }}
          <v-icon right>mdi-file-download</v-icon>
        </v-btn>
      </template>
    </div>

    <div v-if="isProjectsPage">
      <div class="d-flex justify-start justify-md-end mt-2">
        <import-status
          :status="importStatus.status"
          :url="importStatus.url"
          :error-document-name="errorDocumentName"
          :get-status="getImportStatus"
          @show-modal="showModal($t('import.process'))"
        />
      </div>
    </div>

    <import-status-stub v-else class="mt-2" />

    <router-view
      :send-request.sync="sendRequest"
      :access-to-rooms="accessToRooms"
      @update:is-data-missing="updateIsDataMissing"
    />
  </div>
</template>

<script>
// Constants
import { COMPANY_SECTIONS_RIGHTS } from '@/store/modules/user/types';

// Services
import notificationService from '@/services/notification';
import realtyService from '@/services/realty';
import { mapGetters } from 'vuex';

// Components
import FileInput from '@/components/FileInput.vue';
import ImportStatus from '@/components/ImportStatus.vue';
import IconButton from '@/components/IconButton.vue';
import ImportStatusStub from '@/components/ImportStatusStub.vue';

// Utils
import { retry } from '@/utils/delay';

// Constants
import {
  PROJECTS,
  PROJECTS_ARCHIVE,
  UNITS_CREATE,
  ROOMS_CREATE,
  PROJECTS_CREATE,
  BUILDINGS_CREATE,
} from '@/constants/routes';
import { IN_PROCESS, READY } from '@/constants/importStatuses';
import { PROJECTS as PROJECTS_SUBJECT } from '@/constants/subjects';
import { UPDATE } from '@/constants/actions';

export default {
  name: 'ProjectsLayout',

  components: {
    FileInput,
    ImportStatus,
    IconButton,
    ImportStatusStub,
    InfoModal: () => import('@/components/InfoModal.vue'),
  },

  inject: ['media'],

  data() {
    return {
      pages: {
        projects: this.$route.query.page || 1,
        archive: this.$route.query.page || 1,
      },
      importStatus: {},
      modal: {
        show: false,
        message: '',
      },

      isDataMissing: false,

      sendMessagesAfterMounted: true,

      errorDocumentName: 'projects_errors.xlsx',
      sendRequest: false,
    };
  },

  computed: {
    canUpdateProjects() {
      return this.$can(UPDATE, PROJECTS_SUBJECT);
    },

    isProcessing() {
      return this.importStatus?.status === IN_PROCESS;
    },

    projectsLink() {
      return { name: PROJECTS, query: { page: this.pages.projects } };
    },

    archiveLink() {
      return { name: PROJECTS_ARCHIVE, query: { page: this.pages.archive } };
    },

    isProjectsPage() {
      return this.$route.name === PROJECTS;
    },

    projectRoute() {
      return { name: PROJECTS_CREATE, query: { prevPage: this.pages.projects } };
    },

    buildingRoute() {
      return { name: BUILDINGS_CREATE, query: { prevPage: this.pages.projects } };
    },

    unitRoute() {
      return { name: UNITS_CREATE, query: { prevPage: this.pages.projects } };
    },

    roomRoute() {
      return { name: ROOMS_CREATE, query: { prevPage: this.pages.projects } };
    },

    ...mapGetters('user', {
      companySections: COMPANY_SECTIONS_RIGHTS,
    }),

    accessToRooms() {
      return !!this.companySections.find(section => section.name === 'rooms');
    },
  },

  watch: {
    // eslint-disable-next-line
    '$route.query.page': function(newVal) {
      if (this.isProjectsPage) {
        this.pages.projects = newVal;
        return;
      }

      this.pages.archive = newVal;
    },
  },

  mounted() {
    this.fetchImportStatus();
  },

  methods: {
    showModal(message) {
      this.modal.show = true;
      this.modal.message = message;
    },

    closeModal() {
      this.modal.show = false;
      this.modal.message = '';
    },

    async getImportStatus() {
      const { status } = await realtyService.getProjectImportStatus();

      return status;
    },

    // eslint-disable-next-line func-names
    fetchImportStatus: retry(async function() {
      const uploadState = await realtyService.getProjectImportStatus();
      this.importStatus = uploadState;

      if (uploadState.status === READY && !this.sendMessagesAfterMounted) {
        this.sendRequest = true;

        notificationService.success(this.$t('import.upload_completed_successfully'), 2000);
      }

      this.sendMessagesAfterMounted = false;

      return uploadState.status === IN_PROCESS;
    }),

    exportProject() {
      realtyService.downloadProject('project.xlsx');
    },

    async importProject(files) {
      const project = files[0];
      const status = await this.getImportStatus();

      if (status === IN_PROCESS) {
        this.showModal(this.$t('import.process_try_again'));
        return;
      }
      realtyService
        .importProject(project)
        .then(() => {
          this.importStatus.status = IN_PROCESS;
        })
        .finally(() => {
          this.fetchImportStatus();
        });
    },

    updateIsDataMissing(value) {
      this.isDataMissing = value;
    },
  },
};
</script>

<style lang="scss">
.projects-layout {
  height: 100%;

  &__link {
    color: rgba($--black-color-0, 0.5) !important;
    text-decoration: none;

    &--active {
      color: $--black-color-0 !important;
      text-decoration: underline;
    }
  }
}
</style>
