/* eslint-disable camelcase */
import { extend } from 'vee-validate';
import { required, integer, confirmed, max_value, min_value } from 'vee-validate/dist/rules';
import { AsYouType, getExampleNumber } from 'libphonenumber-js';
import examples from 'libphonenumber-js/examples.mobile.json';
import { EMAIL, URL } from '@/constants/regularExpresions';
import { checkEmptyParams } from '@/utils/many';
import i18n from './i18n';

extend('max_value', {
  ...max_value,
  message: (_, { max }) => `${i18n.t('validation.messages.max_value')} ${max}`,
});

extend('min_value', {
  ...min_value,
  message: (_, { min }) => `${i18n.t('validation.messages.min_value')} ${min}`,
});

extend('required', {
  ...required,
  message: () => i18n.t('validation.messages.required'),
});

extend('confirmed', {
  ...confirmed,
  // message: () => i18n.t('validation.messages.required'),
  message: 'Password mismatch',
});

extend('integer', {
  ...integer,
  message: () => i18n.t('validation.messages.integer'),
});

extend('positive', {
  validate(value) {
    return typeof value === 'number' && value >= 0;
  },
  message: () => i18n.t('validation.messages.positive'),
});

extend('url', {
  validate(value) {
    // eslint-disable-next-line
    return URL.test(value);
  },
  message: () => i18n.t('validation.messages.url'),
});

extend('percent', {
  validate(value) {
    return typeof value === 'number' && value >= 0 && value <= 100;
  },
  message: () => i18n.t('validation.messages.percent'),
});

extend('allLoaded', {
  validate(value) {
    return value?.every(item => {
      return !item.isLoading;
    });
  },
  message: () => i18n.t('validation.messages.wait_upload'),
});

extend('email', {
  validate(value) {
    return EMAIL.test(value);
  },
  message: () => i18n.t('error.invalid_email'),
});

extend('phone', {
  validate(value) {
    const phoneValidator = new AsYouType();

    phoneValidator.input(value);

    return phoneValidator.isValid();
  },
  message: (_, { _value_ }) => {
    const phoneValidator = new AsYouType();
    phoneValidator.input(_value_);

    const phoneCountry = phoneValidator.getCountry();
    const phoneExample = getExampleNumber(phoneCountry, examples)?.number;

    if (phoneExample) {
      return i18n.t('error.invalid_phone_with_example', { example: phoneExample });
    }

    return i18n.t('error.invalid_phone');
  },
});

extend('password', {
  validate(value) {
    return value?.length >= 8;
  },
  message: () => i18n.t('error.min_characters'),
});

extend('truethy', {
  validate(value) {
    return value;
  },
  message: () => i18n.t('validation.messages.required'),
});

extend('max', {
  validate(value, { max }) {
    return value.length <= max;
  },
  params: ['max'],
  message: (_, { max }) => i18n.t('validation.messages.max_length', { max }),
});

extend('min', {
  validate(value, { min }) {
    return value.length >= min;
  },
  params: ['min'],
  message: (_, { min }) => i18n.t('validation.messages.min_length', { min }),
});

extend('manyRequired', {
  validate(value) {
    return !checkEmptyParams(value);
  },
  message: () => i18n.t('validation.messages.required'),
});
