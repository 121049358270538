<template>
  <v-menu v-model="openMenu" offset-y left :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <v-btn large class="mr-3 primary--text" elevation="0" color="secondary" v-bind="attrs" v-on="on">
        {{ $t('button.import') }}
        <v-icon right>mdi-file-upload</v-icon>
      </v-btn>
    </template>

    <v-list>
      <file-input
        v-slot="{ on, attrs }"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        @input="importClients"
      >
        <v-list-item :disabled="isProcessing" v-bind="attrs" v-on="on" @click.prevent>
          <v-list-item-title>
            <v-icon right>mdi-file-upload</v-icon>
            {{ $t('button.import_clients') }}
          </v-list-item-title>
        </v-list-item>
      </file-input>

      <file-input
        v-slot="{ on, attrs }"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        @input="importAddresses"
      >
        <v-list-item :disabled="isProcessing" v-bind="attrs" v-on="on" @click.prevent>
          <v-list-item-title>
            <v-icon right>mdi-file-upload</v-icon>
            {{ $t('button.addresses') }}
            <v-tooltip top max-width="250" @click.stop.prevent>
              <template #activator="{ on: textOn, attrs: textAttrs }">
                <v-icon small color="gray" v-bind="textAttrs" v-on="textOn">mdi-information</v-icon>
              </template>

              <span>{{ $t('clients.import_addresses_tooltip') }}</span>
            </v-tooltip>
          </v-list-item-title>
        </v-list-item>
      </file-input>
    </v-list>
  </v-menu>
</template>

<script>
// Components
import FileInput from '@/components/FileInput.vue';

export default {
  name: 'ClientsImportMenu',

  components: { FileInput },

  props: {
    isProcessing: { type: Boolean, default: false },
  },

  data() {
    return {
      openMenu: false,
    };
  },

  methods: {
    importClients(file) {
      this.$emit('import-clients', file);
      this.openMenu = false;
    },

    importAddresses(file) {
      this.$emit('import-addresses', file);
      this.openMenu = false;
    },
  },
};
</script>
