<template>
  <v-autocomplete
    ref="autocomplete"
    v-model="modelWrapper"
    class="form-autocomplete"
    :label="label"
    :items="itemsWithValue"
    :loading="isLoading"
    :item-text="itemText"
    :item-value="itemValue"
    :search-input.sync="search"
    :value-comparator="compareValues"
    :error-messages="errors"
    return-object
    :disabled="disabled"
    v-on="inheritedListeners"
  >
    <template #prepend-item>
      <div v-intersect="endIntersect" class="v-autocomplete__intersect" />
    </template>
    <template v-slot:item="{ item }">
      <p class="text-truncate" style="max-width: 450px;">
        {{ typeof itemText === 'string' ? item[itemText] : itemText(item) }}
      </p>
    </template>
    <template #append-item>
      <slot name="append-item"></slot>
    </template>
  </v-autocomplete>
</template>

<script>
// http
import client from '@/http/client';
import { flushPromises } from '@/utils/scheduler';
import { createModelWrapper, getInheritedListeners } from '@/utils/components';
import { debounce } from '@/utils/delay';
import { isFunction } from '@/utils/common';

export default {
  name: 'SchemaFormAutocomplete',

  inheritAttrs: false,

  props: {
    value: {
      type: Object,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    payload: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Array,
      default: () => [],
    },
    queryItems: {
      type: Function,
      default: null,
    },
    compareValues: {
      type: Function,
      required: true,
    },
    itemText: {
      type: [String, Function],
      default: undefined,
    },
    itemValue: {
      type: [String, Function],
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isLoading: false,
      search: null, // `null` don`t run watch effect
      items: [],
      total: null,
      debouncedFetchItems: null,
      firstChange: true,
    };
  },

  computed: {
    modelWrapper: createModelWrapper('value', 'input'),
    inheritedListeners: getInheritedListeners(['input']),

    itemsWithValue() {
      if (!this.modelWrapper) return this.items;

      const foundItem = this.items.find(item => item.id === this.modelWrapper.id);

      if (!foundItem) {
        return [this.modelWrapper, ...this.items];
      }

      return this.items;
    },
  },

  watch: {
    search(search) {
      if (search !== this.getItemText()) {
        this.debouncedFetchItems({ search });
      }

      if (this.modelWrapper?.firstName && this.search === '') {
        this.search = null;
        this.modelWrapper = null;
      }
    },

    modelWrapper() {
      if (this.firstChange) {
        // @todo изменить реализацию выяснив, причину возникновения ошибки
        this.$emit('validate');
        this.firstChange = false;
      }
    },

    value(newValue) {
      if (!newValue) {
        this.search = null;
        this.debouncedFetchItems();
      }
    },
  },

  created() {
    this.debouncedFetchItems = debounce(this.fetchNewItems, 300);
    this.fetchItems().then(results => {
      this.items = results;
    });
  },

  methods: {
    endIntersect(entries, observer, isIntersecting) {
      if (isIntersecting && this.total > this.items.length) {
        this.fetchItems({ offset: this.items.length }).then(results => {
          this.items = [...this.items, ...results];
        });
      }
    },

    async fetchItems({ search = this.search, offset = 0 } = {}) {
      if (this.$options.cancelRequest) {
        this.$options.cancelRequest();
        await flushPromises();
      }

      const cancelSource = client.getCancelToken();
      this.$options.cancelRequest = cancelSource.cancel;
      this.isLoading = true;

      return this.queryItems({ search: search || null, offset }, { cancelToken: cancelSource.token })
        .then(({ count, results }) => {
          this.total = count;
          return results;
        })
        .finally(() => {
          this.isLoading = false;
          this.$refs.autocomplete?.updateMenuDimensions();
        });
    },

    async fetchNewItems({ search = this.search } = {}) {
      if (this.$options.cancelRequest) {
        this.$options.cancelRequest();
        await flushPromises();
      }

      const cancelSource = client.getCancelToken();
      this.$options.cancelRequest = cancelSource.cancel;
      this.isLoading = true;

      return this.queryItems({ search: search || null }, { cancelToken: cancelSource.token })
        .then(({ count, results }) => {
          this.total = count;
          this.items = [];
          this.$nextTick(() => {
            this.items = results;
          });
        })
        .finally(() => {
          this.isLoading = false;
          this.$refs.autocomplete?.updateMenuDimensions();
        });
    },

    getItemText() {
      const { itemText, modelWrapper } = this;

      if (modelWrapper && typeof itemText === 'string') return modelWrapper[itemText];
      if (modelWrapper && isFunction(itemText)) return itemText(modelWrapper);

      return null;
    },
  },
  cancelRequest: null,
};
</script>

<style lang="scss">
.v-autocomplete__content {
  .v-list {
    position: relative;

    &-item__title {
      width: 100%;
      white-space: nowrap !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
    }
  }
  .v-autocomplete__intersect {
    position: absolute;
    bottom: 0;
  }
}

.form-autocomplete {
  .v-input__slot {
    padding-bottom: 6px;
    padding-left: 12px;
  }

  .v-input__control > .v-input__slot:before {
    border-color: rgba($--blue-color-5, 0.15) !important;
  }
}
</style>
