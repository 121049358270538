<template>
  <div class="announcement">
    <info-modal v-model="dialog.open" :title="dialog.title" :content="dialog.content" @close="closeDialog">
      <v-row no-gutters>
        <v-col cols="6" class="pr-2">
          <v-btn v-if="isScheduled" block color="secondary" class="primary--text elevation-0" @click="saveInDraft">
            {{ $t('button.save_in_draft') }}
          </v-btn>
          <v-btn v-else block color="secondary" class="primary--text elevation-0" @click="closeDialog">
            {{ $t('button.no') }}
          </v-btn>
        </v-col>
        <v-col cols="6" class="pl-2">
          <v-btn block color="primary" class="elevation-0" @click="removeItem">
            {{ $t('button.yes') }}
          </v-btn>
        </v-col>
      </v-row>
    </info-modal>

    <entity-status-list v-if="isEmployee" :statuses="entityStatuses">
      <template v-if="!media.isMobile">
        <v-btn
          v-if="canUpdate"
          large
          class="elevation-0"
          color="primary"
          :to="{ name: $options.ANNOUNCEMENTS_CREATE }"
          exact
        >
          {{ $t('announcements.new_announcement') }}
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
        <v-btn large class="primary--text ml-3 elevation-0" color="secondary" @click="exportAnnouncement">
          {{ $t('button.export') }}
          <v-icon right>mdi-file-download</v-icon>
        </v-btn>
      </template>
      <announcements-mobile-menu
        v-else-if="isDataMissing"
        :can-update="canUpdate"
        :can-display-mobile-create-button="canDisplayMobileCreateButton"
        @export-announcement="exportAnnouncement"
      />
    </entity-status-list>

    <h1 v-else class="text-h5 text-md-h4 font-weight-bold">{{ $t('announcements.page_title') }}</h1>

    <announcement-panel class="mt-3 mt-md-7" :search.sync="search" @update:order-by="setOrderBy">
      <announcements-mobile-menu
        v-if="canDisplayMobileCreateButton"
        :can-update="canUpdate"
        @export-announcement="exportAnnouncement"
      />
    </announcement-panel>

    <div v-if="isLoading" class="d-flex align-center justify-center" style="min-height:200px">
      <v-progress-circular indeterminate color="primary" />
    </div>

    <missing-data v-else-if="isDataMissing" class="announcement__missing-data" :status="status" />

    <template v-else>
      <list-loading v-if="isDataFetching" />
      <template v-else>
        <announcement-list v-show="canDisplayAnnouncementList" class="announcement__list mt-8" :items="announcements">
          <template #default="{ image, title, clients, content, itemId, dispatchTime }">
            <announcement-item
              :image="image"
              :title="title"
              :recipient-list="clients"
              :content="content"
              :item-id="itemId"
              :dispatch-time="dispatchTime"
              :is-scheduled="isScheduled"
              :is-drafts="isDrafts"
              @remove="remove"
            />
          </template>
        </announcement-list>

        <base-pagination v-if="isMoreThanOnePage" v-model="pageModel" :length="pagination.pageCount" class="mt-10" />
      </template>
    </template>
  </div>
</template>

<script>
// Http
import client from '@/http/client';

// Utils
import { flushPromises } from '@/utils/scheduler';
import { debounce } from '@/utils/delay';
import { scrollToTop } from '@/utils/scroll';

// Services
import announcementService from '@/services/announcement';
import analyticsService from '@/services/analytics';

// Constants
import {
  ANNOUNCEMENTS_SENT,
  ANNOUNCEMENTS_SCHEDULED,
  ANNOUNCEMENTS_DRAFTS,
  ANNOUNCEMENTS_CREATE,
} from '@/constants/routes';
import { UPDATE } from '@/constants/actions';
import * as subjects from '@/constants/subjects';
import { SCHEDULED, DRAFTS } from '@/constants/entityStatuses';
import { NEWS_LIST, NEWS_SORTING } from '@/constants/analyticsActions';
import { MANAGEMENT_COMPANY_EMPLOYEE } from '@/constants/roles';

// Components
import EntityStatusList from '@/components/EntityStatusList.vue';
import AnnouncementPanel from '@/components/Announcement/Panel.vue';
import AnnouncementList from '@/components/Announcement/List.vue';
import AnnouncementItem from '@/components/Announcement/Item.vue';
import BasePagination from '@/components/BasePagination.vue';
import InfoModal from '@/components/InfoModal.vue';
import MissingData from '@/components/Announcement/MissingData.vue';
import ListLoading from '@/components/ListLoading.vue';
import AnnouncementsMobileMenu from '@/components/Announcement/MobileMenu.vue';

export default {
  name: 'Announcement',

  inject: ['media'],

  components: {
    EntityStatusList,
    AnnouncementPanel,
    AnnouncementList,
    AnnouncementItem,
    BasePagination,
    InfoModal,
    MissingData,
    ListLoading,
    AnnouncementsMobileMenu,
  },

  props: {
    status: { type: String, required: true },
    page: { type: Number, default: 1 },
  },

  data() {
    return {
      isLoading: false,
      announcements: [],
      pagination: {
        limit: 6,
        pageCount: 1,
      },
      search: '',
      isDataFetching: false,
      orderBy: '',
      isDataMissing: false,
      dialog: {
        open: false,
        title: '',
        content: '',
        targetId: -1,
      },
    };
  },

  computed: {
    isEmployee() {
      return this.$store.state.user.role === MANAGEMENT_COMPANY_EMPLOYEE;
    },

    canDisplayAnnouncementList() {
      return !this.isDataFetching && !this.isDataMissing;
    },

    isMoreThanOnePage() {
      return this.pagination.pageCount > 1;
    },

    pageModel: {
      get() {
        return this.page;
      },

      set(page) {
        const oldQuery = this.$route.query;
        this.$router.replace({ query: { ...oldQuery, p: page } });
      },
    },

    isScheduled() {
      return this.status === SCHEDULED;
    },

    isDrafts() {
      return this.status === DRAFTS;
    },

    entityStatuses() {
      return [
        {
          name: ANNOUNCEMENTS_SENT,
          text: this.$t('announcements.sent'),
        },
        {
          name: ANNOUNCEMENTS_SCHEDULED,
          text: this.$t('announcements.scheduled'),
        },
        {
          name: ANNOUNCEMENTS_DRAFTS,
          text: this.$t('announcements.drafts'),
        },
      ];
    },

    canUpdate() {
      return this.$can(UPDATE, subjects.ANNOUNCEMENTS);
    },

    canDisplayMobileCreateButton() {
      return this.isEmployee && this.media.isMobile;
    },

    offset() {
      return (this.pageModel - 1) * this.pagination.limit;
    },
  },

  watch: {
    status() {
      this.search = '';
      this.isLoading = true;
      this.fetchAnnouncements().then(() => {
        this.isDataMissing = !this.announcements.length;
      });
    },

    search() {
      this.pageModel = 1;
      this.debounceFetchAnnouncements();
    },

    orderBy() {
      this.fetchAnnouncements();
    },

    pageModel() {
      this.fetchAnnouncements();
      scrollToTop();
    },
  },

  async mounted() {
    this.fetchAnnouncements();
    analyticsService.track(NEWS_LIST);
  },

  methods: {
    async removeItem() {
      await announcementService.delete(this.dialog.targetId);
      this.closeDialog();
      this.fetchAnnouncements();
    },

    async saveInDraft() {
      await announcementService.update({ id: this.dialog.targetId, status: 'draft' });
      this.closeDialog();
      this.fetchAnnouncements();
    },

    closeDialog() {
      this.dialog.open = false;
    },

    openDialog() {
      this.dialog.open = true;
    },

    remove(id) {
      if (this.isScheduled) {
        this.removeScheduled(id);
        return;
      }

      this.removeDraft(id);
    },

    removeScheduled(id) {
      this.dialog.content = this.$t('announcements.remove_scheduled_content');
      this.dialog.title = this.$t('announcements.remove_scheduled_title');
      this.dialog.targetId = id;
      this.openDialog();
    },

    removeDraft(id) {
      this.dialog.content = this.$t('announcements.remove_draft_content');
      this.dialog.title = this.$t('announcements.remove_draft_title');
      this.dialog.targetId = id;
      this.openDialog();
    },

    setOrderBy(value) {
      this.orderBy = value;
      analyticsService.track(NEWS_SORTING, analyticsService.createNewsSortingPayload(value));
    },

    getAnnouncements({ ...params }) {
      return this.isEmployee
        ? announcementService.getAllForEmployees(params)
        : announcementService.getAllForClients(params);
    },

    async fetchAnnouncements() {
      if (this.$options.cancelFetchAnnouncements) {
        this.$options.cancelFetchAnnouncements();
        await flushPromises();
      }

      this.isDataFetching = true;
      const cancelSource = client.getCancelToken();
      this.$options.cancelFetchAnnouncements = cancelSource.cancel;

      try {
        const { results, count } = await this.getAnnouncements({
          status: this.status,
          search: this.search,
          limit: this.pagination.limit,
          offset: this.offset,
          orderBy: this.orderBy,
          config: {
            cancelToken: cancelSource.token,
          },
        });

        this.isDataMissing = false;
        if (results.length === 0 && !this.search && count === 0) this.isDataMissing = true;

        this.pagination.pageCount = Math.ceil(count / this.pagination.limit);

        this.announcements = results.map(announcement => {
          return {
            ...announcement,
            content: announcement.content
              .replace(/\n/g, '<br>')
              .replace(/href/gi, 'target="_blank" rel="noopener noreferrer" href'),
          };
        });
      } catch (err) {
        if (err.message) console.error(err.message);
      } finally {
        this.$options.cancelFetchAnnouncements = null;
        this.isDataFetching = false;
        this.isLoading = false;
      }
    },

    // eslint-disable-next-line func-names
    debounceFetchAnnouncements: debounce(function() {
      this.fetchAnnouncements();
    }, 200),

    exportAnnouncement() {
      announcementService.download();
    },
  },

  cancelFetchAnnouncements: null,

  ANNOUNCEMENTS_CREATE,
};
</script>

<style lang="scss">
$page-offset: 269px;

.announcement {
  &__missing-data {
    height: 100%;
  }
  &__loader-container {
    min-height: 916px;

    @media (max-width: map.get($--screens, 'sm')) {
      min-height: calc(100vh - #{$page-offset});
    }
  }
}
</style>
