<template>
  <div class="mb-2">
    <!-- <p class="mb-0 text--secondary">{{ $t(`requests.field_${change.fieldType}`) }}</p> -->

    <!-- <div v-if="isText" class="d-flex align-center">
      <span>{{ change.oldValue }}</span>
      <arrow-right class="mx-2 flex-shrink-0" width="24px" height="24px" fill="#1E1E22" />
      <span>{{ change.newValue }}</span>
    </div>

    <div v-if="isDate" class="d-flex align-center">
      <span>{{ formatDateWithTime(change.oldValue) }}</span>
      <arrow-right class="mx-2 flex-shrink-0" width="24px" height="24px" fill="#1E1E22" />
      <span>{{ formatDateWithTime(change.newValue) }}</span>
    </div>

    <div v-if="isAttachments">
      <view-field-file-list :value="formatAttachments(change.oldValue)" />
      <arrow-right class="mx-2 flex-shrink-0" width="24px" height="24px" fill="#1E1E22" />
      <view-field-file-list :value="formatAttachments(change.newValue)" />
    </div>

    <div v-if="isRowadder" class="d-flex align-center">
      <span>{{ normalizeTableValue(change.oldValue) }}</span>
      <arrow-right class="mx-2 flex-shrink-0" width="24px" height="24px" fill="#1E1E22" />
      <span>{{ normalizeTableValue(change.newValue) }}</span>
    </div>

    <div v-if="isDropdown" class="d-flex align-center">
      <span>{{ change.oldValue.join(', ') }}</span>
      <arrow-right class="mx-2 flex-shrink-0" width="24px" height="24px" fill="#1E1E22" />
      <span>{{ change.newValue.join(', ') }}</span>
    </div> -->
  </div>
</template>

<script>
// Utils
import { translateDate } from '@/utils/dateFormatting';
import { format } from 'date-fns';

// Icons
// import ArrowRight from '@/assets/icons/ArrowRight.svg';

// Components
// import ViewFieldFileList from '@/components/schema/ViewFieldFileList.vue';

export default {
  name: 'ActivitiesViewClientChanges',

  inject: ['media'],

  components: {
    // ArrowRight,
    // ViewFieldFileList,
  },

  props: {
    change: {
      type: Object,
      required: true,
    },
  },

  computed: {
    isText() {
      return this.change.fieldType === 'text';
    },
    isDate() {
      return this.change.fieldType === 'date_time';
    },
    isAttachments() {
      return this.change.fieldType === 'attachments';
    },
    isRowadder() {
      return this.change.fieldType === 'rowadder';
    },
    isDropdown() {
      return this.change.fieldType === 'dropdown';
    },
    // @TODO добавить текст и вывод
    // message() {
    //   if (!this.change.oldValue) {
    //     return this.$t('activities.value_added');
    //   }

    //   if (!this.change.newValue) {
    //     return this.$t('activities.value_removed');
    //   }

    //   return null;
    // },
  },

  methods: {
    formatDateWithTime(date) {
      if (date) {
        const noFormatDate = new Date(date);
        return translateDate(format(noFormatDate, 'd L yyyy kk:mm'));
      }
      return date;
    },

    normalizeTableValue(value) {
      let result = '';
      value.forEach((item, index) => {
        if (index) {
          result += `; ${item.join(', ')}`;
        } else {
          result += item.join(', ');
        }
      });
      return result;
    },

    formatAttachments(attachments) {
      return attachments.map((url, id) => ({ url, id }));
    },
  },
};
</script>
