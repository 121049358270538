import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import ru from 'vuetify/lib/locale/ru';

Vue.use(Vuetify);

export default new Vuetify({
  breakpoint: {
    mobileBreakpoint: 1400,
    thresholds: {
      xs: 600,
      sm: 960,
      md: 1600,
      lg: 1904,
    },
  },

  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#1829D0',
        secondary: '#E6EBFF',
        info: '#E6EBFF',
        blue: '#00008D',
        blue20: '#002fff',
        blue40: '#4167f0',
        blue50: '#C7D6FF',
        blue70: '#8097ff',
        progress: '#99acff',
        grey: '#757575',
      },
    },
  },
  lang: {
    locales: { ru },
    current: 'ru',
  },
  icons: {
    values: {
      sort: 'mdi-arrow-down',
    },
  },
});
