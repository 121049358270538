<template>
  <base-mobile-list class="client-mobile-list" :items="items">
    <template v-slot:item="{ item }">
      <v-row no-gutters>
        <v-col cols="12">
          <view-list-field :label="$t('client.project')">
            {{ item.projectName }}
          </view-list-field>
        </v-col>
      </v-row>

      <div class="mt-6">
        <v-row no-gutters>
          <v-col cols="6" class="pr-1">
            <view-list-field :label="$t('client.building')">
              {{ item.buildingName }}
            </view-list-field>
          </v-col>
          <v-col cols="6" class="pl-1">
            <view-list-field :label="$t('client.unit')" align="right">
              {{ item.unitName }}
            </view-list-field>
          </v-col>
        </v-row>
      </div>

      <div class="mt-6">
        <v-btn
          class="client-mobile-list__full-name text-body-1 font-weight-medium px-0 text-none"
          text
          @click="$emit('open', item)"
        >
          {{ item.fullName }}
        </v-btn>
        <ClientStatus :status="item.status" :show-mobile-icon="true" />
        <div class="client-mobile-list__client-type text-caption">{{ item.clientType }}</div>
      </div>

      <v-row v-if="isShortTenantsType" no-gutters>
        <v-col cols="12">
          <view-list-field :label="$t('client.rent')">
            {{ item.periodDate ? dateDoubleFormat(item.periodDate) : '' }}
          </view-list-field>
        </v-col>
      </v-row>

      <div class="d-flex flex-row mt-6">
        <view-list-field v-if="!isShortTenantsType" class="flex-grow-1" :label="$t('client.email')">
          {{ item.email }}
        </view-list-field>
        <view-list-field v-else class="flex-grow-1" :label="$t('client.cost')">
          {{ item.totalRentCost }}
        </view-list-field>
        <div class="d-flex align-end text-caption text--secondary">
          <div class="d-flex align-center">
            {{ $t('label.select') }}

            <base-checkbox
              :input-value="getSelectedStatus(item)"
              class="client-mobile-list__checkbox ml-1 pt-0 mt-0"
              hide-details
              @change="select(item)"
            />
          </div>
        </div>
      </div>
    </template>
  </base-mobile-list>
</template>

<script>
// Components
import BaseMobileList from '@/components/BaseMobileList.vue';
import ViewListField from '@/components/ViewListField.vue';
import BaseCheckbox from '@/components/BaseCheckbox.vue';
import ClientStatus from '@/components/Clients/ClientStatus.vue';

import * as typesClient from '@/constants/typesClients';

import { dateDoubleFormat } from '@/utils/dateFormatting';

export default {
  name: 'ClientsMobileList',

  components: { BaseMobileList, ViewListField, BaseCheckbox, ClientStatus },

  model: {
    prop: 'selectedItems',
    event: 'update:selectedItems',
  },

  props: {
    typeClient: { type: String, required: true },
    items: { type: Array, required: true },
    selectedItems: { type: Array, default: () => [] },
  },

  data() {
    return {
      isSelected: false,
    };
  },

  computed: {
    selectedItemsModel: {
      get() {
        return this.selectedItems;
      },

      set(value) {
        this.$emit('update:selectedItems', value);
      },
    },
    isShortTenantsType() {
      return this.typeClient === typesClient.SHORT_TENANTS;
    },
  },

  methods: {
    getSelectedStatus(item) {
      return this.selectedItemsModel.findIndex(usage => usage.id === item.id) !== -1;
    },

    select(item) {
      if (this.getSelectedStatus(item)) {
        const filteredValue = this.selectedItemsModel.filter(usage => usage.id !== item.id);

        this.selectedItemsModel = filteredValue;
        return;
      }

      this.selectedItemsModel = [...this.selectedItems, item];
    },
    dateDoubleFormat,
  },
};
</script>

<style lang="scss">
.client-mobile-list {
  &__full-name {
    color: $--primary-color !important;
  }

  &__client-type {
    color: $--mobile-list-secondary-font-color !important;
    opacity: 0.5;
  }

  &__checkbox {
    width: 24px;
    min-width: 24px;
    height: 24px;
    min-height: 24px;
  }
}
</style>
