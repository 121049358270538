<template>
  <div
    class="chat-units-header d-flex flex-column secondary primary--text"
    :class="{ 'chat-units-header--open': isOpen }"
  >
    <div v-if="isUnitsMoreThanOne" class="chat-units-header__info text-h6 pl-8 py-5 secondary">
      {{ units.length }} {{ $t('chat.units') }}
      <v-btn class="chat-units-header__info-button" icon height="24" width="24" @click="toggleUnitsHeaderOpen">
        <v-icon class="chat-units-header__info-button" size="20">mdi-information</v-icon>
      </v-btn>
    </div>

    <div v-if="isOpenOrUnitIsOne" class="chat-units-header__unit-wrapper">
      <div class="chat-units-header__unit-inner secondary">
        <chat-unit
          v-for="(unit, index) in units"
          :key="index"
          class="chat-units-header__unit"
          :project-name="unit.project.name"
          :building-name="unit.building.name"
          :unit-name="unit.unit.name"
          :room-name="unit.room?.name"
        />
      </div>
    </div>

    <div v-if="hasNoUnits" class="text-h6 pl-8 py-5 secondary">
      {{ $t('chat.no_units') }}
    </div>
  </div>
</template>

<script>
// Services
import usersService from '@/services/users';

// Components
import ChatUnit from '@/components/Chat/Unit.vue';
import { flushPromises } from '@/utils/scheduler';
import client from '@/http/client';

export default {
  name: 'ChatUnitsHeader',

  components: { ChatUnit },

  props: {
    clientId: { type: [Number, String], default: -1 },
  },

  data: () => ({
    isOpen: false,
    units: [],
    isLoading: false,
    hasNoUnits: false,
  }),

  computed: {
    isUnitsMoreThanOne() {
      return this.units.length > 1;
    },

    isOpenOrUnitIsOne() {
      return this.isOpen || this.units.length === 1;
    },
  },

  watch: {
    units() {
      this.isOpen = false;
    },

    clientId: {
      handler() {
        this.fetchUnits();
      },
      immediate: true,
    },
  },

  methods: {
    toggleUnitsHeaderOpen() {
      this.isOpen = !this.isOpen;
    },

    async fetchUnits() {
      if (this.clientId === -1) return;

      if (this.$options.cancelFetchUnits) {
        this.$options.cancelFetchUnits();
        await flushPromises();
      }

      this.isLoading = true;

      const cancelSource = client.getCancelToken();
      this.$options.cancelFetchUnits = cancelSource.cancel;

      usersService
        .getClientById(this.clientId)
        .then(({ usages }) => {
          this.units = usages.map(usage => {
            const { unit } = usage;
            return {
              project: usage.project,
              building: usage.building,
              unit: unit.parentUnit
                ? { id: unit.parentUnit, name: unit.parentUnitName }
                : { id: unit.id, name: unit.name },
              room: unit.parentUnit ? { id: unit.id, name: unit.name } : null,
            };
          });

          if (this.units.length === 0) {
            this.hasNoUnits = true;
          } else {
            this.hasNoUnits = false;
          }
        })
        .finally(() => {
          this.isLoading = false;
          this.$options.cancelFetchUnits = null;
        });
    },
  },

  cancelFetchUnits: null,
};
</script>

<style lang="scss">
$top-and-bottom-indentation: 326px;

.chat-units-header {
  flex: 0 0 auto;
  min-height: 72px;

  &__info-button {
    color: inherit !important;
  }

  &__unit-wrapper {
    position: relative;
  }

  &--open {
    .chat-units-header {
      &__unit-inner {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        max-height: calc(100vh - #{$top-and-bottom-indentation});
        overflow: auto;
      }

      &__unit-inner,
      &__info {
        box-shadow: $--shadow-main;
      }

      &__info,
      &__unit:not(:last-child) {
        border-bottom: 1px solid rgba($--chat-header-unit-border, 0.15) !important;
      }
    }
  }
}
</style>
