import * as routes from '@/constants/routes';
import * as entityStatuses from '@/constants/entityStatuses';
import * as subjects from '@/constants/subjects';
import * as actions from '@/constants/actions';
import { canAbility } from './middleware';

export default {
  path: '/calendar',
  name: routes.CALENDAR,
  component: () => import('@/views/Calendar/index.vue'),
  meta: {
    middleware: [canAbility([actions.READ], subjects.CALENDAR)],
  },
  props: route => ({ status: entityStatuses.ACTIVE, page: Number(route.query.page) || 1 }),
};
