<template>
  <div>
    <draggable tag="ul" :list="value" class="list-group ps-0" handle=".handle">
      <li v-for="(item, index) in value" :key="item.position" class="d-flex align-center py-4">
        <v-btn icon class="me-3">
          <v-icon class="handle">mdi-reorder-horizontal</v-icon>
        </v-btn>
        <div class="d-flex flex-column">
          <p class="mb-0 font-weight-medium">{{ item.name }}</p>
          <span class="text-caption grey--text">{{ item.type }}</span>
        </div>
        <div class="ms-auto">
          <form-modal
            title="Edit field"
            :schema="$options.schemaAddField"
            :initial-data="getInitialData(index)"
            @submit="saveField($event, index)"
          >
            <template v-slot:activator="{ on, attrs }">
              <icon-button exact :width="44" v-bind="attrs" v-on="on">
                <v-icon color="primary">mdi-pencil</v-icon>
              </icon-button>
            </template>
            <template #footer="{ valid }">
              <v-card-actions class="px-0 py-0">
                <v-btn large type="submit" color="primary" :disabled="!valid">ADD</v-btn>
              </v-card-actions>
            </template>
          </form-modal>
          <icon-button exact :width="44" class="ms-3" @click.stop="deleteItem(index)">
            <v-icon color="primary">mdi-delete</v-icon>
          </icon-button>
        </div>
      </li>
    </draggable>

    <span />

    <form-modal
      :title="$t('request.add_field')"
      :schema="$options.schemaAddField"
      :initial-data="initialData"
      @submit="addField($event, value.length)"
    >
      <template v-slot:activator="{ on, attrs }">
        <div class="d-flex pb-2">
          <span v-bind="attrs" class="d-flex align-center primary--text text-uppercase" v-on="on">
            <v-icon color="primary" class="me-4">mdi-plus</v-icon>
            {{ addButtonText }}
          </span>
        </div>
      </template>

      <template #footer="{ valid }">
        <v-card-actions class="px-0 py-0">
          <v-btn large type="submit" color="primary" :disabled="!valid">ADD</v-btn>
        </v-card-actions>
      </template>
    </form-modal>
  </div>
</template>

<script>
// Models
import { UNIQUE_KEY } from '@/schemas/config';
import { createModelData } from '@/schemas/createModelData';

// Schema
import { schemaAddField } from '@/schemas/request.schema';

// Components
import IconButton from '@/components/IconButton.vue';
import draggable from 'vuedraggable';
import FormModal from './FormModal.vue';

export default {
  UNIQUE_KEY,

  name: 'ShemaFormFieldTableColumns',

  inject: {
    addPropertyValueItem: 'addPropertyValueItem',
    setPropertyValue: 'setPropertyValue',
    deletePropertyValueItem: 'deletePropertyValueItem',
    filterSchema: 'filterSchema',
  },

  components: { FormModal, IconButton, draggable },

  inheritAttrs: false,

  props: {
    propPath: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    children: {
      type: Array,
      default: () => [],
    },
    uniqueKey: {
      type: String,
      default: null,
    },
    addButtonText: {
      type: String,
      default: 'button.add_field',
    },
  },

  data() {
    return {
      dialog: false,
      isValid: false,
      initialData: createModelData(schemaAddField),
    };
  },

  methods: {
    addItem() {
      this.addPropertyValueItem({
        propPath: this.propPath,
        schema: this.children,
      });
    },

    addField(field, index) {
      this.addItem();
      Object.keys(field).forEach(key => {
        this.setPropertyValue({
          propPath: [...this.propPath, index, key],
          value: field[key],
        });
      });
    },

    getInitialData(index) {
      const initData = { ...this.value[index] };
      return initData;
    },

    saveField(field, index) {
      Object.keys(field).forEach(key => {
        this.setPropertyValue({ propPath: [...this.propPath, index, key], value: field[key] });
      });
      this.dialog = false;
    },

    deleteItem(index) {
      this.deletePropertyValueItem({
        propPath: this.propPath,
        index,
      });
    },

    getPropertyPath(child, index) {
      return child.prop ? [...this.propPath, index, child.prop] : [...this.propPath, index];
    },
  },

  schemaAddField,
};
</script>
