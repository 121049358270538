<template>
  <v-row>
    <v-col cols="12" md="5">
      <view-field class="font-weight-medium" :label="$t('label.name')">
        {{ name }}&nbsp;<template v-if="isRequired">*</template>
      </view-field>
    </v-col>
    <v-col cols="12" md="7">
      <view-field class="font-weight-medium" :label="$t('label.type')">
        {{ isMultiply ? `(${$t('requests.field_dropdown_multiselect')})` : '' }}
        {{ $t(`requests.field_${fieldType}`) }}<span v-if="fieldHasList">:</span>
        <template v-if="isRowadder"> {{ joinValues(field.fieldOptions.columns) }} </template>
        <template v-if="isDropdown"> {{ joinValues(field.fieldOptions.selectionOptions) }} </template>
      </view-field>
    </v-col>
  </v-row>
</template>

<script>
import ViewField from '@/components/ViewField.vue';

export default {
  name: 'SchemaViewFieldRequestFieldsListItem',

  components: { ViewField },

  props: {
    field: { type: Object, required: true },
  },

  computed: {
    name() {
      return this.field.name;
    },

    fieldType() {
      return this.field.fieldType;
    },

    isRequired() {
      return this.field.isRequired;
    },

    isMultiply() {
      return this.field.isMultiply;
    },

    isRowadder() {
      return this.fieldType === 'rowadder';
    },

    isDropdown() {
      return this.fieldType === 'dropdown';
    },

    fieldHasList() {
      return this.isRowadder || this.isDropdown;
    },
  },

  methods: {
    joinValues(values) {
      return values.map(value => value.title).join(', ');
    },
  },
};
</script>
