<template>
  <div class="announcements-modify">
    <v-container class="announcements-modify__container px-0" fluid>
      <entity-modify-header v-if="!isLoading" class="mb-10" :title="title">
        <icon-button v-if="isEdit" color="secondary" @click="deleteAnnouncement">
          <v-icon>mdi-delete</v-icon>
        </icon-button>
      </entity-modify-header>

      <form-builder
        v-if="!isLoading"
        enable-cache
        :schema="$options.schema"
        :initial-data="initialData"
        @submit="submit"
      >
        <template #footer="{ valid }">
          <v-row>
            <v-col cols="6" class="pr-1 pr-md-3">
              <v-btn color="secondary" block class="primary--text" :to="backRoute" exact>{{ $t('button.back') }}</v-btn>
            </v-col>

            <v-col cols="6" class="pl-1 pl-md-3">
              <v-btn color="secondary" block class="primary--text" type="submit" @click="setDraftStatus">
                <v-icon left>mdi-note-edit</v-icon>
                {{ $t('button.save_draft') }}
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-btn
                type="submit"
                color="primary"
                block
                :disabled="!valid"
                :loading="isUpload"
                @click="setScheduledStatus"
              >
                {{ submitButtonText }}
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </form-builder>
    </v-container>
  </div>
</template>

<script>
// node_modules
import { format } from 'date-fns';

// Services
import announcementService from '@/services/announcement';
import notificationService from '@/services/notification';
import analyticsService from '@/services/analytics';

// Constants
import { ANNOUNCEMENTS_SENT, ANNOUNCEMENTS_DETAILED } from '@/constants/routes';
import { DRAFT, SCHEDULED } from '@/constants/announcementStatuses';
import {
  CREATE_NEWS_DRAFT,
  EDIT_NEWS_DRAFT,
  CREATE_SCHEDULED_NEWS,
  EDIT_SCHEDULED_NEWS,
  DELETE_NEWS_DRAFT,
  DELETE_SCHEDULED_NEWS,
} from '@/constants/analyticsActions';

// Models
import { schema } from '@/schemas/announcement.schema';
import { createModelData } from '@/schemas/createModelData';

// Utils
import { extractParamsFromMultiSelectObject } from '@/utils/many';

// Components
import FormBuilder from '@/components/schema/FormBuilder.vue';
import EntityModifyHeader from '@/components/EntityModifyHeader.vue';
import IconButton from '@/components/IconButton.vue';

export default {
  name: 'AnnouncementsModify',

  components: { FormBuilder, EntityModifyHeader, IconButton },

  inject: ['media'],

  props: {
    isEdit: { type: Boolean, default: false },
    id: { type: Number, default: -1 },
  },

  data() {
    return {
      isLoading: false,
      isUpload: false,
      initialData: createModelData(schema),
      status: SCHEDULED,
    };
  },

  computed: {
    title() {
      if (!this.isEdit) return this.$t('announcement.new_announcement');

      if (this.media.isMobile) {
        if (this.initialData.status === DRAFT) return this.$t('announcement.draft');
        return this.$t('announcement.scheduled');
      }

      if (this.initialData.status === DRAFT) return this.$t('announcement.draft_announcement');
      return this.$t('announcement.scheduled_announcement');
    },

    submitButtonText() {
      if (this.initialData.status === DRAFT) return this.$t('button.send');
      if (this.isEdit) return this.$t('button.save');
      return this.$t('button.send');
    },

    backRoute() {
      return { name: ANNOUNCEMENTS_SENT };
    },
  },

  async created() {
    try {
      this.isLoading = true;

      let announcement;
      if (this.isEdit) {
        announcement = await announcementService.getById(this.id);
        this.initialData = { ...announcement };
        const currentDate = new Date(announcement.dispatchTime);
        this.initialData.date = format(currentDate, 'yyy-MM-dd');
        this.initialData.time = format(currentDate, 'HH:mm');
        this.initialData.image = announcement.image ? [{ ...announcement.image, selected: true }] : [];
        this.initialData.content = `<p>${announcement.content.replace(/\\n/, '</p><p>')}</p>`;
      }

      const { results: defaultImages } = await announcementService.getDefaultImages();

      this.initialData.defaultImage = defaultImages.map(image => ({
        ...image,
        selected: false,
      }));

      if (this.isEdit && announcement.defaultImage) {
        const foundImage = this.initialData.defaultImage.find(image => image.id === announcement.defaultImage.id);

        foundImage.selected = true;
      } else if (this.initialData.defaultImage.length && !this.isEdit) {
        this.initialData.defaultImage[0].selected = true;
      }
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    async deleteAnnouncement() {
      analyticsService.track(this.status === DRAFT ? DELETE_NEWS_DRAFT : DELETE_SCHEDULED_NEWS);

      await announcementService.delete(this.id);
      this.$router.push({ name: ANNOUNCEMENTS_SENT });
    },

    setScheduledStatus() {
      this.status = SCHEDULED;
    },

    setDraftStatus() {
      this.status = DRAFT;
    },

    submit(data) {
      const preparedData = {};
      const foundImage = data.image.filter(image => image?.selected).pop();

      preparedData.dispatchTime = format(new Date(`${data.date}T${data.time}`), "yyyy-MM-dd'T'HH:mmxxxxx");
      preparedData.title = data.title;
      preparedData.content = data.content
        .replace(/<\/p><p>/gi, '\n')
        .replace(/<\/?[b-zB-Z]+>/gi, '')
        .replace(/ rel="noopener noreferrer" target="_blank"/gi, '');
      preparedData.defaultImage = data.defaultImage.filter(image => image?.selected).pop()?.id || null;
      preparedData.image = foundImage?.id || foundImage?.backgroundFileId || null;
      preparedData.isPushNotificationEnabled = data.isPushNotificationEnabled;
      preparedData.isEmailNotificationEnabled = data.isEmailNotificationEnabled;
      preparedData.status = this.status;
      preparedData.projects = extractParamsFromMultiSelectObject(data.projects);
      preparedData.buildings = extractParamsFromMultiSelectObject(data.buildings);
      preparedData.units = {
        ...extractParamsFromMultiSelectObject(data.units),
        assignAllCurrentAndFuture: data.units.all,
      };
      preparedData.rooms = {
        ...extractParamsFromMultiSelectObject(data.rooms),
        assignAllCurrentAndFuture: data.rooms.all,
      };
      preparedData.clients = extractParamsFromMultiSelectObject(data.clients);

      this.isUpload = true;

      if (this.$options.notificationItem) {
        notificationService.remove(this.$options.notificationItem);
      }

      const sendData = this.isEdit ? announcementService.update : announcementService.create;

      if (this.status === DRAFT) {
        analyticsService.track(this.isEdit ? EDIT_NEWS_DRAFT : CREATE_NEWS_DRAFT);
      } else {
        analyticsService.track(this.isEdit ? EDIT_SCHEDULED_NEWS : CREATE_SCHEDULED_NEWS);
      }

      sendData({ ...preparedData, id: this.id })
        .then(post => {
          notificationService.success(
            this.isEdit ? this.$t('announcement.edited') : this.$t('announcement.created'),
            2000
          );
          this.$router.push({ name: ANNOUNCEMENTS_DETAILED, params: { id: post?.id || post?.data?.id } });
        })
        .catch(error => {
          this.$options.notificationItem = notificationService.error(
            `${this.$t('error.found_errors')} ${Object.keys(error?.response?.data).join(', ')}`
          );
        })
        .finally(() => {
          this.isUpload = false;
        });
    },
  },

  notificationItem: null,
  schema,
};
</script>

<style lang="scss">
.announcements-modify {
  &__container {
    max-width: 548px !important;
  }
}
</style>
