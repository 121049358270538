// формирующие модель данных
export const STRING = 'string';
export const NUMBER = 'number';
export const AMOUNT = 'amount';
export const BOOLEAN = 'boolean';
export const PROJECT = 'project';
export const BUILDING = 'building';
export const EMPLOYEES = 'employees';
export const PERFORMERS = 'performers';
export const ENUM = 'enum';
export const MANY_ENUM = 'manyEnums';
export const IMAGE_LIST = 'imageList';
export const FILE_LIST = 'fileList';

export const TEXT_EDITOR = 'textEditor';
export const LINK = 'link';
export const DATE = 'date';
export const MONTH_DATE = 'monthDate';
export const DATE_WITH_PANEL = 'dateWithPanel';
export const CLIENT = 'client';
export const CONTRACTOR = 'contractor';
export const PERCENT = 'percent';
export const UNIT = 'unit';
export const ROOM = 'room';
export const TOTAL_AMOUNT = 'totalAmount';
export const SQUARE = 'square';
export const COUNTRY = 'country';
export const CATEGORY = 'category';
export const MANY_ITEMS = 'manyItems';
export const MANY_PROJECTS = 'manyProjects';
export const MANY_CALENDAR_PROJECTS = 'manyCalendarProjects';
export const MANY_UNITS = 'manyUnits';
export const MANY_CALENDAR_UNITS = 'manyCalendarUnits';
export const MANY_ROOMS = 'manyRooms';
export const MANY_BUILDINGS = 'manyBuildings';
export const MANY_CALENDAR_BUILDINGS = 'manyCalendarBuildings';
export const MANY_CLIENTS = 'manyClients';
export const MANY_PERFORMERS = 'manyPerformers';
export const MANY_PERFORMERS_FOR_TASK = 'manyPerformersForTask';
export const MANY_PERFORMERS_FOR_PROJECT = 'manyPerformersForProject';
export const MANY_SERVICE_TYPES = 'manyServiceTypes';
export const MANY_CONTRACTORS_TYPES = 'manyContractorsTypes';
export const MANY_PAYMENT_TYPE = 'manyPaymentType';
export const TIME = 'time';
export const CREDENTIALS_VARIANT = 'credentialsVariant';
export const COAST = 'coast';
export const SEARCH_BAR = 'searchBar';
export const SCRIPT_ITEM = 'scriptItem';
export const CONTRACTS = 'contracts';
export const UNIT_CONTRACTS = 'unitContracts';
export const UNIT_ENUM = 'unitEnum';
export const EMPLOYEES_ROLES = 'employeesRoles';
export const ROLES_LIST = 'rolesList';
export const TEAM_LIST = 'teamList';
export const ROLE = 'role';
export const MANY_ROLES = 'manyRoles';
export const MANY_REQUEST_ROLES = 'manyRequestRoles';
export const CLIENT_TYPE = 'clientType';
export const AGREEMENT_STATUS_SELECT = 'agreementStatusSelect';
export const REQUEST = 'Request';
export const REQUEST_MANY_TYPES = 'RequestManyTypes';
export const REQUEST_TYPE = 'RequestType';
export const REQUEST_MANY_CATEGORY = 'RequestManyCategory';
export const REQUEST_CATEGORY = 'RequestCategory';
export const REQUEST_ICON = 'RequestIcon';
export const REQUEST_FIELDS_LIST = 'RequestFieldsList';
export const REQUEST_FIELDS_LIST_ENUM = 'RequestFieldsListEnum';
export const TABLE_COLUMNS = 'TableColumns';
export const REQUEST_CLIENTS_TYPE = 'RequestClientsType';
export const REQUEST_PAYMENT = 'RequestPayment';
export const TASK_PAYMENTS = 'TaskPayments';

export const REQUEST_FORM_LIST = 'requestFormList';
export const REQUEST_FORM_DROPDOWN = 'requestFormDropdown';
export const LIST = 'list';
export const COLUMN_LIST = 'columnList';
export const SCRIPT_LIST = 'scriptList';
export const ACTIVITIES = 'activityList';

// не влияют на модель данных
export const TITLE = 'title';
export const TITLED_SECTION = 'titledSection';
export const SUBTITLED_SECTION = 'subtitledSection';
export const TITLED_SECTION_WITH_STATUS = 'titledSectionWithStatus';
export const UNIT_SALES_HISTORY = 'unitSalesHistory';
export const HISTORY = 'history';
export const ROW = 'row';
export const FLEX_ROW = 'flex-row';
export const EXPANSION_SECTION = 'expansionSection';
export const CARD_SECTION = 'cardSection';
export const TASK_STATUS_CHANGE = 'taskStatusChange';
export const EXPANSION_PANEL = 'expansionPanel';
export const TYPES_WITHOUT_EFFECT = Object.freeze([
  ROW,
  FLEX_ROW,
  TITLED_SECTION,
  SUBTITLED_SECTION,
  CARD_SECTION,
  TASK_STATUS_CHANGE,
  LIST,
  EXPANSION_PANEL,
]);
