<template>
  <div>
    <div v-for="(field, index) in value" :key="field.position">
      <v-divider v-if="index" class="schema-view-field-contact-container__divider" />
      <request-list-item :field="field" />
    </div>
  </div>
</template>

<script>
import RequestListItem from './ViewFieldRequestFieldsListItem.vue';

export default {
  name: 'SchemaViewFieldRequestFieldsList',

  components: { RequestListItem },

  props: {
    value: { type: Array, required: true },
  },

  methods: {
    fieldHasList(fieldType) {
      return fieldType === 'table' || fieldType === 'dropwdown';
    },
    joinValues(values) {
      return values.map(value => value.title).join(', ');
    },
  },
};
</script>
