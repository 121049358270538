<template>
  <FormAutocomplete
    ref="autocomplete"
    v-model="modelWrapper"
    :label="label"
    :errors="errors"
    :query-items="queryItems"
    item-text="name"
    :item-value="item => item"
    :compare-values="$options.compareById"
    v-on="inheritedListeners"
  />
</template>

<script>
// Utils
import { isEqual } from '@/utils/common';
import { compareById } from '@/utils/comparators';
import { createModelWrapper, getInheritedListeners } from '@/utils/components';

// Services
import { fetchProjects } from '@/services/select';

// Components
import FormAutocomplete from './FormAutocomplete.vue';

export default {
  compareById,

  name: 'SchemaFormFieldProject',

  components: {
    FormAutocomplete,
  },

  inheritAttrs: false,

  props: {
    value: {
      type: Object,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    payload: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    modelWrapper: createModelWrapper('value', 'input'),
    inheritedListeners: getInheritedListeners(['input']),
  },

  watch: {
    payload(newPayload, oldPayload) {
      if (!isEqual(newPayload, oldPayload)) {
        this.$refs.autocomplete?.debouncedFetchItems();
      }
    },
  },

  methods: {
    queryItems(payload, config) {
      if (payload.search === '') {
        return fetchProjects({ ...payload }, config).then(({ count, results }) => ({ count, results }));
      }
      return fetchProjects({ ...payload, ...this.payload }, config).then(({ count, results }) => ({ count, results }));
    },
  },
};
</script>
