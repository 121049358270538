<template>
  <div>
    <div class="activities-filter mb-8">
      <v-radio-group v-model="filterActivities" hide-details row>
        <v-chip
          v-for="(filter, index) in filterActivitiesList"
          :key="filter.value"
          tag="label"
          label
          :color="filter.value === filterActivities ? 'secondary' : '#F5F5F5'"
          class="font-weight-bold text-uppercase"
          :class="[
            filter.value === filterActivities && 'primary--text',
            index !== filterActivitiesList.length - 1 ? 'me-4' : '',
          ]"
          style="cursor:pointer;"
        >
          <span>{{ $t(filter.title) }}</span>
          <v-radio v-show="false" :value="filter.value" />
        </v-chip>
      </v-radio-group>
    </div>
    <div v-if="isLoading" class="d-flex justify-center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-else class="activities-list">
      <activities-view-item
        v-for="activity in activities"
        :key="activity.id"
        :activity="activity"
        :class="media.isMobile ? 'mb-12' : 'mb-10'"
      />
    </div>
  </div>
</template>

<script>
// Components
import ActivitiesViewItem from './ActivitiesViewItem.vue';

export default {
  name: 'ActivitiesView',

  inject: ['media'],

  components: {
    ActivitiesViewItem,
  },

  props: {
    isLoading: { type: Boolean, default: false },
    status: { type: String, default: 'all' },
    activities: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      filterActivitiesList: [
        { title: this.$t('tasks.activities_all'), value: 'all' },
        { title: this.$t('tasks.activities_history'), value: 'status' },
        { title: this.$t('tasks.activities_client_changes'), value: 'history' },
        { title: this.$t('tasks.activities_comments'), value: 'comment' },
      ],
    };
  },

  computed: {
    filterActivities: {
      get() {
        return this.status;
      },
      set(status) {
        this.$emit('change-status', status);
      },
    },
  },
};
</script>

<style lang="scss">
.activities-filter {
  .v-input--radio-group__input {
    width: auto !important;
    flex-wrap: nowrap !important;
  }

  @media screen and (max-width: 959px) {
    margin: 0 -8px;

    .v-input__slot {
      padding: 0 8px;
      overflow: auto;
      scrollbar-width: 0;

      &::-webkit-scrollbar {
        width: 0;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }
    }

    .v-input--radio-group__input {
      & > label {
        flex: 0 0 auto;
      }
    }
  }
}
</style>
