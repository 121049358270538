<template>
  <v-container class="requests-modify">
    <entity-modify-header class="mb-10" :title="title" />

    <div v-if="!isLoading" class="requests-tabs">
      <form-builder
        :initial-data="initialData"
        :enable-cache="enableCache"
        is-tabs
        :tabs="tabs"
        :active-tab="tab"
        @submit="submit"
        @change-tab="changeTab"
      >
        <template #footer="{ valid }">
          <v-row class="footer-row mt-4">
            <v-col>
              <v-btn v-if="tab >= 1" color="secondary" block class="primary--text" @click="changeTab(tab - 1)">
                {{ $t('button.back') }}
              </v-btn>
              <v-btn v-else color="secondary" block class="primary--text" :to="backRoute">{{
                $t('button.cancel')
              }}</v-btn>
            </v-col>
            <v-col>
              <v-btn v-if="tab < tabs.length - 1" type="button" color="primary" block @click.prevent="changeTab">
                {{ $t('button.continue') }}
              </v-btn>
              <v-btn v-else :disabled="!valid" type="submit" color="primary" block>{{ submitButtonText }}</v-btn>
            </v-col>
          </v-row>
        </template>
      </form-builder>
    </div>

    <v-progress-circular v-else color="primary" size="32" width="2" indeterminate />
  </v-container>
</template>

<script>
// Components
import EntityModifyHeader from '@/components/EntityModifyHeader.vue';
import FormBuilder from '@/components/schema/FormBuilder.vue';

// Constants
import { TAB_BASE_INFO, TAB_REQUEST_FORM, TAB_DETAILS } from '@/constants/tabs';
import { REQUESTS, REQUESTS_DETAILED } from '@/constants/routes';

// Schema
import { schemaBaseInfo, schemaRequestForm, schemaDetails } from '@/schemas/request.schema';

// Utils
import { createModelData } from '@/schemas/createModelData';

// Services
import requestsService from '@/services/requests';
import notificationService from '@/services/notification';

export default {
  name: 'RequestModify',

  components: { FormBuilder, EntityModifyHeader },
  props: {
    isEdit: { type: Boolean, default: false },
    id: { type: Number, default: null },
    prevPage: { type: Number, default: 1 },
  },

  data() {
    return {
      isLoading: false,
      initialData: createModelData([...schemaBaseInfo, ...schemaRequestForm, ...schemaDetails]),
      schemaList: [schemaBaseInfo, schemaRequestForm, schemaDetails],
      tab: 0,
      tabs: [
        {
          label: 'tab.base_info',
          tab: TAB_BASE_INFO,
          schema: schemaBaseInfo,
        },
        {
          label: 'tab.request_form',
          tab: TAB_REQUEST_FORM,
          schema: schemaRequestForm,
        },
        {
          label: 'tab.details',
          tab: TAB_DETAILS,
          schema: schemaDetails,
        },
      ],
    };
  },

  computed: {
    submitButtonText() {
      return this.$t(this.isEdit ? 'button.edit_request' : 'button.add_request');
    },

    title() {
      return this.isEdit ? this.$t('requests.edit_request') : this.$t('requests.add_request');
    },

    backRoute() {
      return this.isEdit
        ? { name: REQUESTS_DETAILED, query: { id: this.$route.params.id } }
        : this.$route.params.prevPage || { name: REQUESTS };
    },

    schema() {
      return this.schemaList[this.tab];
    },

    enableCache() {
      return this.tab === 2;
    },
  },

  created() {},

  mounted() {
    if (this.isEdit) {
      this.isLoading = true;
      requestsService
        .getRequestById(this.id)
        .then(request => {
          const newRequest = {
            ...request,
            type: { ...request.category.categoryType },
            fields: request.fields
              .map(field => {
                return {
                  ...field,
                  columns: field.fieldOptions.columns,
                  options: field.fieldOptions.selectionOptions,
                  isMultiply: field.fieldOptions.selectionMode === 'multiply',
                  fieldType: {
                    value: field.fieldType,
                    text: `requests.field_${field.fieldType}`,
                  },
                };
              })
              .sort((a, b) => a.position - b.position),
            projects: {
              all: request.ruleAutoRequestAssign?.assignAllNewProjects || false,
              include: request.ruleAutoRequestAssign?.assignAllNewProjects ? [] : [...request.projects],
              exclude: [],
            },
            buildings: {
              all: request.ruleAutoRequestAssign?.assignAllNewBuildings || false,
              include: request.ruleAutoRequestAssign?.assignAllNewBuildings ? [] : [...request.buildings],
              exclude: [],
            },
            units: {
              all: request.ruleAutoRequestAssign?.assignAllNewUnits || false,
              include: request.ruleAutoRequestAssign?.assignAllNewUnits ? [] : [...request.units],
              exclude: [],
            },
            rooms: {
              all: request.ruleAutoRequestAssign?.assignAllNewRooms || false,
              include: request.ruleAutoRequestAssign?.assignAllNewRooms ? [] : [...request.rooms],
              exclude: [],
            },
            roles: {
              rolesPending: {
                all: false,
                include: [...request.roles.pending],
                exclude: [],
              },
              rolesInProgress: {
                all: false,
                include: [...request.roles.inProgress],
                exclude: [],
              },
              rolesCompleted: {
                all: false,
                include: [...request.roles.completed],
                exclude: [],
              },
            },
            clientType: request.clientType === 'all' ? ['owner', 'tenant'] : [request.clientType],
          };
          this.initialData = newRequest;
        })

        .finally(() => {
          this.isLoading = false;
        });
    }
  },

  methods: {
    submit(data) {
      const bodyRequest = this.createBodyRequest(data);

      const saveRequest = this.isEdit ? requestsService.updateRequest : requestsService.createRequest;

      if (this.$options.notificationItem) {
        notificationService.remove(this.$options.notificationItem);
      }

      this.isLoading = true;

      saveRequest(bodyRequest, data.id)
        .then(request => {
          const message = this.isEdit ? this.$t('request.request_edited') : this.$t('request.request_added');
          notificationService.success(message, 2000);
          this.$router.push({ name: REQUESTS_DETAILED, params: { id: request.id || this.$route.params.id } });
        })
        .catch(error => {
          this.initialData = { ...data };

          const keys = Object.keys(error?.response?.data);

          if (keys.length) {
            for (let i = 0; i < keys.length; i += 1) {
              const key = keys[i];
              const errorText = this.getErrorText(error.response.data, key);
              this.$options.notificationItem = notificationService.error(`${key}: ${errorText}`);
            }
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    createBodyRequest(data) {
      return {
        ...data,
        description: data.description || undefined,
        category: data.category?.id,
        fields: data.fields.map((field, index) => ({
          ...field,
          fieldType: field.fieldType.value,
          fieldOptions: this.getFieldOptions(field),
          position: index,
        })),
        projects: this.formatProjects(data.projects),
        buildings: this.formatProjects(data.buildings),
        units: this.formatProjects(data.units),
        rooms: this.formatProjects(data.rooms),
        roles: {
          pending: [...data.roles.rolesPending.include.map(role => role.id)],
          inProgress: [...data.roles.rolesInProgress.include.map(role => role.id)],
          completed: [...data.roles.rolesCompleted.include.map(role => role.id)],
        },
        clientType: data.clientType.length > 1 ? 'all' : data.clientType[0],
        contractor: data.contractor?.id,
      };
    },
    formatProjects(projects) {
      return {
        assignAllCurrentAndFuture: projects.assignAllCurrentAndFuture || projects.all,
        all: projects.all,
        exclude: projects.exclude.map(project => project.id),
        include: projects.include.map(project => project.id),
      };
    },
    getFieldOptions(field) {
      const fieldOptions = {};
      switch (field.fieldType.value) {
        case 'rowadder':
          fieldOptions.columns = [...field.columns];
          break;
        case 'dropdown':
          fieldOptions.selectionMode = field.isMultiply ? 'multiply' : 'single';
          fieldOptions.selectionOptions = field.options.map(option => {
            return {
              ...option,
              isSelected: false,
            };
          });
          break;
        default:
          break;
      }

      return fieldOptions;
    },
    changeTab(newTab) {
      this.tab = typeof newTab === 'number' ? newTab : this.tab + 1;
    },
  },

  notificationItem: null,
  schemaBaseInfo,
  schemaRequestForm,
  schemaDetails,
};
</script>

<style lang="scss">
.requests-modify {
  max-width: 548px !important;
}
</style>
