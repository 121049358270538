import * as schemaItemTypes from './schemaItemTypes';
import { rules } from './validationRules';

const dependentProperties = {
  EMAIL: 'email',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  PHONE: 'phone',
  PROJECTS: 'projects',
  SECTIONS: 'sections',
};

/**
 * @type {Array<import('./schema').default>}
 */
export const schema = [
  {
    type: schemaItemTypes.TITLED_SECTION,
    label: 'employee.personal_information',
    children: [
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            type: schemaItemTypes.STRING,
            label: 'employee.first_name',
            prop: 'firstName',
            rules: [rules.REQUIRED],
          },
          {
            type: schemaItemTypes.STRING,
            label: 'employee.last_name',
            prop: 'lastName',
            rules: [rules.REQUIRED],
          },
        ],
      },
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            type: schemaItemTypes.STRING,
            label: 'employee.phone',
            prop: dependentProperties.PHONE,
            hideSpinButtons: true,
            defaultValue: '',
            rules: [rules.REQUIRED],
          },
          {
            type: schemaItemTypes.STRING,
            label: 'employee.email',
            prop: dependentProperties.EMAIL,
            rules: [rules.REQUIRED, rules.EMAIL],
          },
        ],
      },
    ],
  },
  {
    label: 'employee.roles',
    type: schemaItemTypes.TITLED_SECTION,
    children: [
      {
        type: schemaItemTypes.ROLES_LIST,
        prop: 'roles',
        uniqueKey: 'id',
        children: [
          {
            type: schemaItemTypes.ROLE,
            label: 'employee.role',
            prop: 'role',
            rules: [rules.REQUIRED],
          },
          {
            type: schemaItemTypes.MANY_PROJECTS,
            label: 'label.projects',
            prop: dependentProperties.PROJECTS,
            rules: [rules.MANY_REQUIRED],
          },
        ],
      },
    ],
  },
];

export { schema as default };
