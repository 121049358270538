<template>
  <div class="chat-unit primary--text">
    <div class="chat-unit__field-wrapper d-flex justify-space-between align-center pl-8 py-5 secondary">
      <v-row>
        <v-col v-for="(field, index) in fields" :key="index" cols="6" class="chat-unit__field">
          <span class="chat-unit__field-name text-h6 font-weight-medium">{{ field.name }} </span>
          <span class="chat-unit__field-value text-h6">{{ field.value }}</span>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

// Constants
import { COMPANY_SECTIONS_RIGHTS } from '@/store/modules/user/types';

export default {
  name: 'ChatUnit',

  props: {
    projectName: { type: String, default: '' },
    buildingName: { type: String, default: '' },
    unitName: { type: String, default: '' },
    roomName: { type: String, default: '' },
  },

  computed: {
    ...mapGetters('user', {
      companySections: COMPANY_SECTIONS_RIGHTS,
    }),

    accessToRooms() {
      return !!this.companySections.find(section => section.name === 'rooms');
    },
    fields() {
      const fields = [
        {
          name: this.$t('chat.project'),
          value: this.projectName,
        },
        {
          name: this.$t('chat.building'),
          value: this.buildingName,
        },
        {
          name: this.$t('chat.unit'),
          value: this.unitName,
        },
      ];

      return this.accessToRooms && this.roomName
        ? [...fields, { name: this.$t('chat.room'), value: this.roomName }]
        : fields;
    },
  },
};
</script>

<style lang="scss">
.chat-unit {
  &__field {
    word-break: break-word;
  }

  &__field-wrapper {
    padding-right: 32px;

    @media (min-width: map.get($--screens, 'md')) {
      padding-right: 100px;
    }
  }

  &__field-name {
    opacity: 0.5;
  }

  &__field-value {
    word-break: break-all;
  }
}
</style>
