<template>
  <div class="services" :class="{ 'services--archive': isArchive }">
    <info-modal v-model="modal.show" :title="modal.message" @close="closeModal">
      <v-btn v-if="modal.info" color="primary" block @click="closeModal">{{ $t('services.back_to_services') }}</v-btn>
      <v-row v-else no-gutters>
        <v-col class="pr-2" cols="6">
          <v-btn class="font-weight-bold flex-grow-1" color="primary" block @click="selectAllInPage"
            >{{ $t('button.on_page') }} ({{ pageServicesCount }})</v-btn
          >
        </v-col>

        <v-col class="pl-2" cols="6">
          <v-btn class="font-weight-bold flex-grow-1" color="primary" block @click="selectGlobal"
            >{{ $t('button.all') }} ({{ servicesCount }})</v-btn
          >
        </v-col>
      </v-row>
    </info-modal>

    <entity-status-list :statuses="entityServicesStatuses">
      <template v-if="canDisplayActions">
        <template v-if="!isArchive && !media.isMobile">
          <v-menu offset-y left>
            <template #activator="{ attrs, on }">
              <v-btn large class="mr-3 elevation-0" color="primary" v-bind="attrs" v-on="on">
                {{ $t('button.add') }}
                <v-icon right>
                  mdi-plus
                </v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item :to="categoryCreateRoute">
                {{ $t('button.add_category') }}
              </v-list-item>

              <v-list-item :to="servicesCreateRoute">
                {{ $t('button.add_services') }}
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <services-menu
          :status="status"
          :page="pageModel"
          @archive-services="archiveServices"
          @unarchive-services="unarchiveServices"
          @export-services="exportServices"
        />
      </template>
    </entity-status-list>

    <div v-if="media.isMobile" class="d-flex flex-column mb-1 mb-md-4">
      <mobile-sort
        v-if="isArchive"
        class="mt-2"
        :reverse="canUpdateServices"
        :sort-list="servicesHeaders"
        :sort-by="activeHeader"
        :options.sync="options"
      >
        <services-menu
          v-if="canUpdateServices"
          :page="pageModel"
          :status="status"
          @archive-services="archiveServices"
          @unarchive-services="unarchiveServices"
          @export-services="exportServices"
          @select-services="selectAll"
        />
      </mobile-sort>

      <div class="mb-5 d-flex align-end">
        <mobile-search-menu
          :key="$route.name"
          v-model="queryModel"
          :placeholder="$t('label.services_search')"
          single-line
          full-width
          hide-details
        />
        <services-menu
          v-if="canUpdateServices && !isArchive"
          :page="pageModel"
          :status="status"
          @archive-services="archiveServices"
          @unarchive-services="unarchiveServices"
          @export-services="exportServices"
          @select-services="selectAll(null)"
        />
      </div>
    </div>

    <search-bar
      v-if="!media.isMobile"
      :key="$route.name"
      v-model="queryModel"
      class="mb-6"
      :placeholder="$t('label.services_search')"
      single-line
      full-width
      hide-details
    />

    <div v-if="!isArchive && !media.isMobile">
      <div class="pr-6 pl-12 mb-3 services__table-headers primary--text font-weight-bold">
        <v-simple-checkbox :value="selectedAny" :on-icon="checkboxIcon" color="primary" @click="selectAll(null)" />

        <v-row>
          <v-col cols="1">
            <div class="text-truncate text-center">
              {{ $t('services.order') }}
            </div>
          </v-col>
          <v-col cols="2">
            <div class="text-truncate">
              {{ $t('services.title') }}
            </div>
          </v-col>
          <v-col cols="2">
            <div class="text-truncate">
              {{ $t('services.description') }}
            </div>
          </v-col>
          <v-col cols="3">
            <div class="text-truncate">
              {{ $t('services.projects') }}
            </div>
          </v-col>
          <v-col cols="2">
            <div class="text-truncate">
              {{ $t('services.contractor') }}
            </div>
          </v-col>
          <v-col cols="2">
            <div class="text-end text-truncate">
              {{ $t('services.initial_cost') }}
            </div>
          </v-col>
        </v-row>
      </div>

      <v-progress-linear v-if="tableLoading" indeterminate color="primary"></v-progress-linear>
    </div>

    <div v-if="isLoading" class="d-flex align-center justify-center" style="min-height:200px">
      <v-progress-circular indeterminate color="primary" />
    </div>

    <services-missing-data v-else-if="isDataMissing" class="services__missing-data" :is-archive="isArchive" />

    <template v-else>
      <div class="services__list">
        <list-loading v-if="isLoading" />
        <services-mobile-list
          v-if="isArchive && !isLoading && media.isMobile"
          v-model="selected"
          :items="services"
          @open="openDetailed"
        />
        <base-table
          v-if="isArchive && !isLoading && !media.isMobile"
          v-model="selected"
          hide-default-footer
          show-select
          checkbox-color="primary"
          :items="services"
          :headers="servicesHeaders"
          :loading="tableLoading"
          :options.sync="options"
          :server-items-length="servicesLimit"
          @click:row="openDetailed"
        >
          <template v-slot:[`header.data-table-select`]="{ props, on }">
            <v-menu
              v-if="!media.isMobile"
              v-model="dialogIsOpen"
              :close-on-content-click="false"
              offset-y
              :value="true"
            >
              <template #activator="{ on: modalActivator }">
                <v-simple-checkbox v-bind="props" :ripple="false" @click="selectAll(props, modalActivator)" v-on="on" />
              </template>
              <v-card max-width="352">
                <v-card-text class="text-subtitle-2">
                  {{ $t('services.select_info') }}
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn text class="font-weight-bold" color="primary" @click="selectAllInPage"
                    >{{ $t('button.all_in_page') }} ({{ pageServicesCount }})</v-btn
                  >
                  <v-btn text class="font-weight-bold" color="primary" @click="selectGlobal"
                    >{{ $t('button.all') }} ({{ servicesCount }})</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-menu>
          </template>
          <template v-slot:item.accessibleForProjects="{ item }">
            {{ joinWithLimit(item.accessibleForProjects, 3) }}
          </template>
        </base-table>
        <services-list
          v-if="!isArchive && !isLoading"
          :selected-items="selected"
          :services="services"
          :open-all="openAll"
          :can-update="canUpdateServices"
          @select-item="selectItem"
        ></services-list>
      </div>

      <base-pagination
        v-if="pageCount > 1 && !isLoading && isArchive"
        v-model="pageModel"
        :length="pageCount"
        class="mt-10"
      />
    </template>
  </div>
</template>
<script>
import EntityStatusList from '@/components/EntityStatusList.vue';
import ServicesMenu from '@/components/Services/Menu.vue';
import InfoModal from '@/components/InfoModal.vue';
import ServicesMissingData from '@/components/Services/MissingData.vue';
import SearchBar from '@/components/SearchBar.vue';
import BasePagination from '@/components/BasePagination.vue';
import BaseTable from '@/components/BaseTable.vue';
import ListLoading from '@/components/ListLoading.vue';
import MobileSort from '@/components/MobileSort/index.vue';
import MobileSearchMenu from '@/components/MobileSearchMenu.vue';
import ServicesList from '@/components/Services/List.vue';
import ServicesMobileList from '@/components/Services/MobileList.vue';

import servicesService from '@/services/services';
import {
  SERVICES_CREATE,
  SERVICES_CATEGORY_CREATE,
  SERVICES,
  SERVICES_ARCHIVE,
  SERVICES_DETAILED,
} from '@/constants/routes';
import * as entityStatuses from '@/constants/entityStatuses';
import { UPDATE } from '@/constants/actions';
import { SERVICES as SERVICES_SUBJECT } from '@/constants/subjects';

import joinWithLimit from '@/utils/joinWithLimit';
import { flushPromises } from '@/utils/scheduler';
import client from '@/http/client';
import { camelToSnake } from '@/utils/formatters';
import analyticsService from '@/services/analytics';
import { ARCHIVE_SERVICE, SERVICES_LIST, UNARCHIVE_SERVICE } from '@/constants/analyticsActions';
import {
  CATEGORY,
  EMPTY_CATEGORY,
  SERVICE_WITH_CATEGORY,
  SERVICE_WITHOUT_CATEGORY,
  CATEGORY_BUTTON_ADD_SERVICE,
} from '@/constants/serviceEntity';
import { separateThouthands } from '@/utils/priceFormatting';
import { throttle } from '@/utils/delay';

export default {
  name: 'Services',
  components: {
    EntityStatusList,
    ServicesMenu,
    InfoModal,
    ServicesMissingData,
    SearchBar,
    BaseTable,
    ServicesList,
    ServicesMobileList,
    BasePagination,
    ListLoading,
    MobileSort,
    MobileSearchMenu,
  },
  inject: ['media'],

  props: {
    status: { type: String, required: true },
  },
  data() {
    return {
      services: [],
      open: [],
      servicesCount: 0,
      pageCount: 1,
      pageLimit: 10,
      isDataMissing: false,
      isLoading: true,
      tableLoading: true,
      search: '',
      page: +this.$route.query.page || 1,
      selected: [],
      options: { sortBy: '', sortDesc: null },
      dialogIsOpen: false,
      globalSelect: false,
      modal: {
        show: false,
        message: '',
        info: false,
      },
      openAll: true,
    };
  },
  computed: {
    pageServicesCount() {
      let quantity = 0;
      this.services.forEach(service => {
        if (service.isCategory) {
          quantity += service.services.length;
        } else quantity += 1;
      });
      return quantity;
    },
    entityServicesStatuses() {
      return [
        { id: 0, text: this.$t('services.services'), name: SERVICES },
        { id: 1, text: this.$t('services.archive'), name: SERVICES_ARCHIVE },
      ];
    },
    isArchive() {
      return this.status === entityStatuses.ARCHIVED;
    },
    isSearch() {
      return !!this.search;
    },
    canDisplayActions() {
      return (!this.media.isMobile && this.canUpdateServices) || this.isDataMissing;
    },
    canUpdateServices() {
      return this.$can(UPDATE, SERVICES_SUBJECT);
    },
    servicesCreateRoute() {
      return { name: SERVICES_CREATE };
    },
    categoryCreateRoute() {
      return { name: SERVICES_CATEGORY_CREATE };
    },
    pageModel: {
      get() {
        return this.page;
      },

      set(value) {
        this.$router.push({
          name: this.$route.name,
          query: { page: value },
        });
      },
    },
    queryModel: {
      get() {
        return this.search;
      },

      set(value) {
        this.search = value;
      },
    },
    orderBy() {
      if (!this.options.sortBy || this.options.sortDesc === null) return undefined;

      const header = this.servicesHeaders.find(serviceHeader => serviceHeader.value === this.options.sortBy);

      const value = camelToSnake(header?.sortValue || this.options.sortBy);

      if (this.options.sortDesc === null) return value;
      return this.options.sortDesc ? `-${value}` : value;
    },
    offset() {
      return (this.page - 1) * this.servicesLimit;
    },
    activeHeader() {
      return this.servicesHeaders.find(
        header => header.value === this.options.sortBy || header?.sortValue === this.options.sortBy
      );
    },
    servicesHeaders() {
      return [
        {
          text: this.$t('services.title'),
          value: 'name',
          sortable: true,
          width: '20%',
        },
        {
          text: this.$t('services.description'),
          sortable: true,
          value: 'shortDescription',
          width: '25%',
        },
        { text: this.$t('services.projects'), value: 'accessibleForProjects', sortable: false, width: '25%' },
        { text: this.$t('services.contractor'), value: 'contractor', sortable: true, width: '20%' },
        { text: this.$t('services.initial_cost'), value: 'initialAmount', sortable: true, width: '10%' },
      ];
    },
    servicesLimit() {
      return this.isArchive || this.isSearch ? 10 : 5;
    },
    selectedAny() {
      return !!this.selected.length;
    },
    checkboxIcon() {
      if (this.selected.length) {
        return this.selected.length === this.pageServicesCount ? 'mdi-checkbox-marked' : 'mdi-minus-box';
      }
      return 'checkbox-blank-outline';
    },
  },

  watch: {
    status() {
      this.isLoading = true;
      this.services = [];
      this.search = '';
      this.pageModel = 1;
      this.getServices().then(() => {
        if (!this.services.length && !this.search) {
          this.isDataMissing = true;
        }
      });
    },
    queryModel(newValue, oldValue) {
      if (newValue === oldValue) return;
      this.$options.throttledSearch();
    },
    orderBy() {
      this.getServices();
    },
    page() {
      this.selected = [];
      this.globalSelect = false;
      this.getServices();
    },
    selected(value) {
      if (value.length < this.pageServicesCount) {
        this.globalSelect = false;
      }
    },

    // eslint-disable-next-line
    '$route.query.page': function(newValue) {
      if (newValue) {
        if (this.pageModel === +newValue) {
          return;
        }
        this.page = +newValue || 1;
      }
    },

    // eslint-disable-next-line
    // '$route.name': function(newValue, oldValue) {
    //   if (newValue === oldValue) return;
    //   if (this.isDataMissing) {
    //     this.isDataMissing = false;
    //   }

    //   if (this.pageModel !== 1) {
    //     this.pageModel = 1;
    //     return;
    //   }
    //   this.getServices();
    // },
  },

  mounted() {
    analyticsService.track(SERVICES_LIST);

    if (this.$route.query.page === undefined)
      this.$router.push({
        path: this.$route.path,
        query: { page: this.pageModel },
      });

    this.isLoading = true;
    this.getServices()
      .then(() => {
        if (this.services.length === 0 && !this.search) {
          this.isDataMissing = true;
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  beforeMount() {
    this.$options.throttledSearch = throttle(() => {
      this.getServices();
    }, 500);
  },
  methods: {
    closeModal() {
      this.modal.show = false;
      this.modal.message = '';
      this.modal.info = false;
    },
    selectAllInPage() {
      this.globalSelect = false;
      this.closeSelectDialog();

      if (!this.selected.length) {
        this.selected = this.services;
      }
    },
    selectGlobal() {
      this.globalSelect = true;
      this.closeSelectDialog();
    },
    selectItem(item) {
      if (this.selected.includes(item.id)) {
        this.selected = this.selected.filter(id => id !== item.id);
      } else {
        this.selected.push(item.id);
      }
    },
    selectAll(props) {
      if (this.media.isMobile) {
        // if (this.pageCount > 1) {
        //   this.openSelectDialog();
        // }

        if (this.servicesCount > this.pageServicesCount) {
          this.openSelectDialog();
        }

        if (!props) this.selectManualy();

        return;
      }

      // if (!props?.value && this.pageCount > 1) {
      //   this.openSelectDialog();
      // }

      if (!props?.value && this.servicesCount > this.pageServicesCount) {
        this.openSelectDialog();
      }

      if (!props) this.selectManualy();
    },
    selectManualy() {
      if (this.selected.length) {
        this.selected = [];
      } else {
        this.services.forEach(service => {
          if (service.isCategory) {
            service.children.forEach(child => {
              if (child.id) {
                this.selected.push(child.id);
              }
            });
          } else {
            this.selected.push(service.id);
          }
        });
      }

      // this.selected = this.selected.length === this.pageServicesCount ? [] : this.services;
      // if (!this.selected.length) this.closeSelectDialog();
    },
    // openSelectDialog() {
    //   if (!this.media.isMobile) {
    //     this.dialogIsOpen = true;
    //   } else {
    //     this.modal.message = this.$t('services.select_info');
    //     this.modal.show = true;
    //   }
    // },
    // closeSelectDialog() {
    //   if (!this.media.isMobile) {
    //     this.dialogIsOpen = false;
    //   } else {
    //     this.closeModal();
    //   }
    // },
    showModal(message) {
      this.modal.message = message;
      this.modal.show = true;
      this.modal.info = true;
    },
    openDetailed(item) {
      this.$router.push({ name: SERVICES_DETAILED, params: { id: item.id } });
    },
    archiveServices() {
      analyticsService.track(ARCHIVE_SERVICE);
      const options = { isArchived: true, services: this.selected };

      const archivePromise = this.globalSelect
        ? servicesService.archiveAllServices(options)
        : servicesService.archiveServices(options);

      archivePromise
        .then(() => {
          this.getServices();
          this.showModal(this.$t('services.archive_success'));
        })
        .catch(() => {
          this.showModal(this.$t('services.archive_fail'));
        });
    },
    unarchiveServices() {
      analyticsService.track(UNARCHIVE_SERVICE);
      const options = { isArchived: false, services: this.selected.map(service => service.id) };

      const archivePromise = this.globalSelect
        ? servicesService.archiveAllServices(options)
        : servicesService.archiveServices(options);

      archivePromise
        .then(() => {
          this.getServices();
          this.showModal(this.$t('services.unarchive_success'));
        })
        .catch(() => {
          this.showModal(this.$t('services.unarchive_fail'));
        });
    },
    exportServices() {
      servicesService.downloadService('services.xlsx');
    },
    async getServices() {
      if (this.$options.cancelRequestNewServices) {
        this.$options.cancelRequestNewServices();
        await flushPromises();
      }

      this.servicesCount = 0;
      this.selected = [];
      this.tableLoading = true;
      this.openAll = false;

      try {
        const { count, results: services } =
          this.isArchive || this.search ? await this.getAnotherServices() : await this.getServicesWithCategory();

        this.servicesCount = count;

        this.pageCount = Math.ceil(this.servicesCount / this.pageLimit);
        this.services = services.map(service => this.normalizeData(service)).sort((a, b) => a.order - b.order);
      } finally {
        this.isLoading = false;
        this.tableLoading = false;
        this.$options.cancelRequestNewServices = null;
      }
    },
    async getServicesWithCategory() {
      const cancelSource = client.getCancelToken();
      this.$options.cancelRequestNewServices = cancelSource.cancel;

      const { offset, search } = this;
      let count = 0;

      const serviceData = await servicesService.getServices(
        { offset, search, isArchived: false, uncategorized: true },
        { cancelToken: cancelSource.token }
      );

      const categoryData = await servicesService.getCategories({ offset, search }, { cancelToken: cancelSource.token });

      const results = [
        ...serviceData.results,
        ...categoryData.results.map(category => {
          return { ...category, isCategory: true };
        }),
      ];

      results.forEach(service => {
        if (service.isCategory) {
          count += service.services.length;
        } else {
          count += 1;
        }
      });

      return { count, results };
    },

    async getAnotherServices(limitServices) {
      const cancelSource = client.getCancelToken();
      this.$options.cancelRequestNewServices = cancelSource.cancel;

      const services = await servicesService.getServices(
        {
          limit: limitServices || this.servicesLimit,
          offset: this.offset,
          search: this.search,
          orderBy: this.orderBy,
          isArchived: this.status === entityStatuses.ARCHIVED,
        },
        {
          cancelToken: cancelSource.token,
        }
      );

      if (this.search) {
        const { results: categories } = await servicesService.getCategories();

        const searchData = {
          count: services.count,
          next: services.next,
          previous: services.previous,
          results: [],
        };

        services.results.forEach(service => {
          if (service.category) {
            const category = categories.find(item => item.id === service.category);
            const exitingCategory = searchData.results.find(item => item.isCategory && item.id === category.id);
            if (exitingCategory) {
              exitingCategory.services.push(service);
            } else {
              searchData.results.push({
                ...category,
                isCategory: true,
                services: [service],
              });
            }
          } else {
            searchData.results.push(service);
          }
        });

        this.openAll = true;
        return searchData;
      }

      return services;
    },
    normalizeData(service) {
      if (this.isArchive) {
        return {
          ...service,
          type: SERVICE_WITHOUT_CATEGORY,
          initialAmount: `${separateThouthands(service.initialAmount)} ${this.$t('invoice.euro')}`,
          contractor: service.contractor.name,
        };
      }

      return service.isCategory
        ? {
            ...service,
            type: service.services.length ? CATEGORY : EMPTY_CATEGORY,
            children: [
              ...service.services
                .map(item => {
                  return {
                    ...item,
                    type: SERVICE_WITH_CATEGORY,
                    contractor: item.contractor.name,
                    initialAmount: `${separateThouthands(item.initialAmount)} ${this.$t('invoice.euro')}`,
                  };
                })
                .sort((a, b) => a.order - b.order),
              {
                categoryId: service.id,
                categoryName: service.name,
                name: 'btn',
                type: CATEGORY_BUTTON_ADD_SERVICE,
              },
            ],
          }
        : {
            ...service,
            type: SERVICE_WITHOUT_CATEGORY,
            initialAmount: `${separateThouthands(service.initialAmount)} ${this.$t('invoice.euro')}`,
            contractor: service.contractor.name,
          };
    },
    joinWithLimit,
  },
  cancelRequestNewServices: null,
};
</script>
<style lang="scss">
.services {
  height: 100%;

  &--archive {
    .text-start {
      color: rgba($--black-color-0, 0.5);
    }
  }

  &__table-headers {
    position: relative;

    .v-input--checkbox,
    .v-simple-checkbox {
      position: absolute;
      left: 16px;
      top: 12px;
      margin: 0;
      padding: 0;
    }
  }

  &__missing-data {
    height: 100%;
  }

  &__list {
    min-height: 455px;
  }

  &__edit-category {
    font-weight: 500 !important;
    color: $--blue-color-0 !important;
    text-decoration: underline;
  }

  &__item {
    width: 100%;
    height: 56px;
  }

  .v-treeview {
    color: $--services-table-color !important;
  }

  .v-treeview-node__toggle,
  .v-treeview-node__level {
    display: none;
  }

  button.v-treeview-node__toggle + button.v-treeview-node__checkbox {
    display: none !important;
  }

  .v-treeview-node__root {
    min-height: auto;
    padding: 0;
    align-items: stretch;
  }

  .v-treeview-node__checkbox {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translate(0, -50%);

    &:disabled {
      display: none;
    }
  }

  .v-treeview-node__content {
    align-items: stretch;
    margin: 0;
  }

  .v-treeview-node:first-child .v-treeview-node__root {
    border-radius: 4px 4px 0 0;
    overflow: hidden;
  }

  .v-treeview-node:last-child .v-treeview-node__root {
    border-radius: 0 0 4px 4px;
    overflow: hidden;
  }

  .v-treeview-node__root::before {
    background: #2317ff !important;

    @media screen and (max-width: 959px) {
      background: transparent !important;
    }
  }

  .v-treeview-node__root {
    &:hover::before {
      opacity: 0.04 !important;
    }
    &.v-treeview-node--active::before {
      opacity: 0.04 !important;
    }
  }

  .v-treeview-node__root:not(:hover) {
    &.v-treeview-node--active::before {
      opacity: 0 !important;
    }
  }
}
</style>
