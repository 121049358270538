<template>
  <v-select
    v-model="modelWrapper"
    class="form-enum-select"
    :loading="isLoading"
    :label="label"
    :error-messages="errors"
    :item-value="item => item"
    :items="filtredSelectOptions"
    :value-comparator="$options.compareByValue"
    v-on="inheritedListeners"
  >
  </v-select>
</template>

<script>
import { createModelWrapper, getInheritedListeners } from '@/utils/components';

import { compareByValue } from '@/utils/comparators';

import { getFormFields } from '@/services/select';

export default {
  name: 'SchemaFormFieldRequestFieldsListEnum',

  inheritAttrs: false,

  props: {
    value: {
      type: [String, Number, Boolean, Object],
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    errors: {
      type: Array,
      default: () => [],
    },
    queryParams: {
      type: [Object],
      default: () => ({}),
    },
    hideItems: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      isLoading: false,
      items: [],
      firstChange: true,
    };
  },

  computed: {
    modelWrapper: createModelWrapper('value', 'input'),
    inheritedListeners: getInheritedListeners(['input']),
    filtredSelectOptions() {
      return this.items.filter(item => !this.hideItems.includes(item.value));
    },
  },

  watch: {
    modelWrapper() {
      if (this.firstChange) {
        this.$emit('validate');
        this.firstChange = false;
      }
    },
  },

  created() {
    this.fetchItems();
  },

  methods: {
    fetchItems() {
      this.isLoading = true;
      getFormFields(this.queryParams)
        .then(items => {
          this.items = items.map(({ name, value }) => ({ text: this.$t(name), value }));
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },

  compareByValue,
};
</script>

<style lang="scss">
.form-enum-select {
  .v-input__slot {
    padding-bottom: 6px;
    padding-left: 12px;
  }
}
</style>
