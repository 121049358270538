<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="chat-message d-flex align-end" :class="{ 'flex-row-reverse': avatarPosition === 'right' }">
    <div class="chat-message__avatar-wrapper" :class="avatarMargin">
      <v-avatar :size="avatarSize" :class="avatarClass">
        <v-icon :size="avatarIconSize" color="white">mdi-account</v-icon>
      </v-avatar>

      <div v-if="isOnline" class="chat-message__online-wrapper rounded-circle">
        <div class="chat-message__online light-green accent-4 rounded-circle" />
      </div>
    </div>

    <v-card
      class="chat-message__body pa-2 px-3 d-flex flex-column rounded-lg"
      :class="[cardBorderRadius, { 'chat-message__body--dark': dark }]"
      tile
    >
      <v-card-title class="chat-message__title pa-0">{{ name }}</v-card-title>

      <v-card-text
        class="chat-message__text pa-0 mb-1 text-body-2"
        :class="{ 'chat-message__text--dark': dark, 'text--disabled': isLoadingTranslate }"
        v-html="displayedText"
      />
      <download-button
        v-for="file in attachedFiles"
        :key="file.id"
        :href="file.url"
        :name="file.name"
        :icon="getFileIcon(file)"
        :dark="dark"
        :size="file.size"
      />

      <div class="d-flex">
        <button
          v-if="canDisplayTranslateButton"
          class="chat-message__translate-button d-flex align-center text-caption text-uppercase mr-3"
          :class="{ 'primary--text': isLoadingTranslate }"
          @click="translateMessage"
        >
          {{ translatingText }}
          <v-progress-circular
            v-if="isLoadingTranslate"
            class="ml-1"
            color="primary"
            size="12"
            width="2"
            indeterminate
          />
          <icon-translate v-else class="ml-1" />
        </button>
        <span v-else></span>

        <span class="chat-message__date ml-auto" :class="{ 'chat-message__date--dark': dark }">{{ date }}</span>
      </div>
    </v-card>
  </div>
</template>

<script>
// Components
import DownloadButton from '@/components/DownloadButton.vue';
import IconTranslate from '@/components/Icons/Translate.vue';

const extensionIcons = {
  doc: 'mdi-file-outline',
  docx: 'mdi-file-outline',
  pdf: 'mdi-file-outline',
  xls: 'mdi-file-outline',
  xlsx: 'mdi-file-outline',
  jpeg: 'mdi-image',
  jpg: 'mdi-image',
  png: 'mdi-image',
};

export default {
  name: 'ChatMessage',

  components: {
    DownloadButton,
    IconTranslate,
  },

  inject: ['media'],

  props: {
    name: { type: String, required: true },
    text: { type: String, required: true },
    date: { type: String, required: true },
    avatarPosition: { type: String, default: 'left' },
    dark: { type: Boolean, default: false },
    isOnline: { type: Boolean, default: false },
    attachedFiles: { type: Array, default: () => [] },
    messageId: { type: Number, required: true },
    translatedText: { type: String, default: '' },
    isLoadingTranslate: { type: Boolean, default: false },
  },

  data() {
    return {
      isShowTranslate: false,
    };
  },

  computed: {
    canDisplayTranslateButton() {
      return !this.dark && this.text;
    },

    translatingText() {
      if (this.isLoadingTranslate) {
        return this.$t('chat.message_translating');
      }

      if (this.isShowTranslate && this.translatedText) {
        return this.$t('chat.reverse_translate');
      }

      return this.$t('chat.translate_message');
    },

    displayedText() {
      if (this.isShowTranslate && this.translatedText) {
        return this.translatedText;
      }

      return this.text.replace(/\n/g, '<br>').replace(/href/gi, 'target="_blank" rel="noopener noreferrer" href');
    },

    avatarClass() {
      return this.dark ? 'chat-message__avatar--dark' : 'chat-message__avatar';
    },

    avatarMargin() {
      return this.avatarPosition === 'left' ? 'mr-3' : 'ml-3';
    },

    cardBorderRadius() {
      return this.avatarPosition === 'left' ? 'rounded-bl-0' : 'rounded-br-0';
    },

    textClass() {
      return `chat-message__text${this.dark ? '--dark' : ''}`;
    },

    dateClass() {
      return `chat-message__date${this.dark ? '--dark' : ''}`;
    },

    avatarSize() {
      return this.media.isMobile ? 24 : 40;
    },

    avatarIconSize() {
      return this.media.isMobile ? 11 : 18;
    },
  },

  methods: {
    getFileIcon(file) {
      const extension = file.url.split('.').pop();
      return extensionIcons[extension];
    },

    translateMessage() {
      if (this.isShowTranslate) {
        this.isShowTranslate = false;
        return;
      }

      if (!this.translatedText) {
        this.$emit('translate-message', this.messageId);
      }

      this.isShowTranslate = true;
    },
  },
};
</script>

<style lang="scss">
.chat-message {
  &__avatar-wrapper {
    position: relative;
  }

  &__body {
    box-shadow: $--shadow-main !important;
    background: $--chat-message-background !important;
    color: $--black-color-0 !important;
    word-break: break-word;

    &--dark {
      background: $--chat-message-background-dark !important;
      color: $--white-color-0 !important;
    }
  }

  &__title,
  &__date {
    font-size: $--font-size-small !important;
    line-height: 20px !important;
  }

  &__text {
    opacity: 0.9;
    color: $--black-color-0 !important;

    &--dark {
      color: $--white-color-0 !important;
    }
  }

  &__date {
    opacity: 0.5;

    &--dark {
      opacity: 0.75;
    }
  }

  &__avatar {
    background: $--chat-message-avatar-color !important;

    &--dark {
      background: $--chat-message-avatar-color-dark !important;
    }
  }

  &__online-wrapper {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 12px;
    height: 12px;
    border: 2px solid $--white-color-0;

    @media (max-width: map.get($--screens, 'sm')) {
      width: 8px;
      height: 8px;
    }
  }

  &__online {
    height: 100%;
    width: 100%;
  }

  &__translate-button {
    color: rgba($--black-color-0, 0.5);

    &:hover {
      color: $--blue-color-20;
    }
  }
}
</style>
