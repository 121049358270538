<template>
  <VueEditor
    v-model="modelWrapper"
    :placeholder="label"
    :editor-toolbar="toolbar"
    :editor-options="editorOptions"
    v-on="inheritedListeners"
  />
</template>

<script>
// Utils
import { createModelWrapper, getInheritedListeners } from '@/utils/components';

// Components
// eslint-disable-next-line import/no-extraneous-dependencies
import { VueEditor } from 'vue2-editor';

export default {
  name: 'SchemaFormFieldTextEditor',

  components: { VueEditor },

  inheritAttrs: false,

  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    errors: {
      type: Array,
      default: () => [],
    },
    customToolbar: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      toolbar: [['link']],
      editorOptions: {
        formats: ['link'],
      },
    };
  },

  computed: {
    modelWrapper: createModelWrapper('value', 'input'),
    inheritedListeners: getInheritedListeners(['input']),
  },
};
</script>
