<!-- eslint-disable import/no-extraneous-dependencies -->
<template>
  <v-form class="chat-input__form flex-column d-flex" @submit.prevent="submit">
    <v-chip-group v-if="files.length" class="mt-2 pa-0" column>
      <v-chip
        v-for="file in files"
        :key="file.id"
        class="chat-input__upload-chip my-2"
        :class="{
          'chat-input__upload-chip--uploading': file.isLoading,
          'chat-input__upload-chip--error': file.isError,
          'white--text': file.isError,
        }"
        :style="`--progress: ${file.progress}%`"
        :color="file.isError ? 'red' : ''"
        close
        @click:close="removeFile(file.id)"
      >
        {{ file.name }}
      </v-chip>
    </v-chip-group>

    <div class="chat-input d-flex mt-2">
      <file-input multiple @input="uploadFile">
        <template #default="{ on, attrs }">
          <v-btn
            class="chat-input__attach-button primary--text"
            color="secondary"
            :width="attachButtonWidth"
            :height="fieldsHeight"
            min-width="40"
            min-height="40"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon class="chat-input__attach-icon" dark>mdi-paperclip</v-icon>
          </v-btn>
        </template>
      </file-input>

      <div class="d-flex flex-column align-self-end flex-grow-1 mx-2 mx-xs-1">
        <VueEditor
          ref="editor"
          v-model="text"
          :class="{ focus: isFocus }"
          :placeholder="$t('label.write_message') + '...'"
          :editor-toolbar="toolbar"
          :editor-options="editorOptions"
          @focus="isFocus = true"
          @blur="isFocus = false"
        />
        <!-- <v-text-field
          ref="input"
          v-model="text"
          class="chat-input__text-field mt-sm-0"
          :placeholder="$t('label.write_message') + '...'"
          :row-hieght="fieldsHeight"
          rows="1"
          hide-details
          auto-grow
          dense
        /> -->
      </div>

      <v-btn
        class="chat-input__send-button white--text"
        :width="sendButtonWidth"
        :height="fieldsHeight"
        :disabled="!canSend"
        min-width="40"
        min-height="40"
        type="submit"
      >
        <template v-if="!media.isMobile">{{ $t('button.send') }}</template>
        <v-icon v-else primary>mdi-send</v-icon>
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import FileInput from '@/components/FileInput.vue';

import { VueEditor } from 'vue2-editor';
// eslint-disable-next-line import/no-extraneous-dependencies

export default {
  name: 'ChatInput',

  inject: ['media'],

  components: {
    FileInput,
    VueEditor,
  },

  props: {
    files: { type: Array, default: () => [] },
    hasActiveChat: { type: Boolean, default: false },
  },

  data() {
    return {
      text: '',
      isFocus: false,
      toolbar: [['link']],
      editorOptions: {
        formats: ['link'],
      },
    };
  },

  computed: {
    fieldsHeight() {
      return this.media.isMobile ? 40 : 44;
    },

    sendButtonWidth() {
      return this.media.isMobile ? 40 : 120;
    },

    attachButtonWidth() {
      return this.media.isMobile ? 40 : 44;
    },

    canSend() {
      return this.files.every(file => file.progress === 100) && this.hasActiveChat;
    },
  },

  methods: {
    submit() {
      this.$emit(
        'send',
        this.text
          .replace(/<\/p><p>/gi, '\n')
          .replace(/<\/?[b-zB-Z]+>/gi, '')
          .replace(/ rel="noopener noreferrer" target="_blank"/gi, '')
      );
      this.text = '';
      this.$refs.editor.quill.setText('');
      this.$refs.editor.quill.focus();
    },

    uploadFile(files) {
      this.$emit('upload-file', files);
    },

    removeFile(fileId) {
      this.$emit('remove-file', fileId);
    },
  },
};
</script>

<style lang="scss">
.chat-input {
  --progress: 0%;

  &__upload-chip:not(#{&}__upload-chip--error) {
    background: linear-gradient(
      90deg,
      rgba(241, 244, 255, 1) var(--progress),
      rgba(232, 232, 233, 1) var(--progress)
    ) !important;
  }

  &__upload-chip {
    &--uploading {
      color: $--chat-chip-font-color !important;
    }
  }

  &__send-button {
    background: $--blue-color-5 !important;
  }

  &__text-field {
    input {
      padding-bottom: 12px !important;
      padding-left: 12px !important;
    }
  }

  .ql-editor {
    min-height: 0 !important;
    max-height: 50px;
  }
  .quillWrapper {
    display: flex;

    .ql-toolbar {
      border: none !important;
    }
    .ql-container {
      flex: 1 1 100%;
      border: none !important;
      border-bottom: 1px solid #0000006b !important;
      transition: border-color 0.25s;
    }

    &.focus .ql-container {
      border-color: #1829d0 !important;
    }

    .ql-editing {
      z-index: 10;
    }
  }
}
</style>
