<template>
  <div class="tasks-mobile-list">
    <div v-for="task in tasks" :key="task.id" class="mb-4">
      <filled-mobile-list>
        <template v-if="!isRecurrenceTask(task.status)" v-slot:header>
          <v-col class="py-3 px-2" cols="6" @click="openDetailed(task.id)">
            <view-list-field :label="task.createdAt"> №{{ task.id }} </view-list-field>
          </v-col>
          <v-col class="py-3 px-2 d-flex align-center justify-end" cols="6">
            <v-chip :color="bgChip">
              <span :class="getClassChipStatus(task.status)">{{ $t(`tasks.${task.status}`) }}</span></v-chip
            >
            <task-menu
              v-if="canUpdate && !isCanceledOrCompletedStatus(task.status)"
              color="blue20"
              :status="task.status"
              :can-update="canUpdate"
              @change-status="changeStatus({ newStatus: $event, id: task.id })"
              @delete="deleteTask(task.id)"
              @edit="editTask({ id: task.id })"
            />
          </v-col>
        </template>
        <template v-else v-slot:header>
          <v-col class="py-3 px-2" cols="6" @click="openDetailed(task.id)">
            <view-list-field :label="task.createdAt"> {{ task.recurrencePeriod }} </view-list-field>
          </v-col>
          <v-col class="py-3 px-2 d-flex align-center justify-end" cols="6">
            <task-menu
              v-if="canUpdate && !isCanceledOrCompletedStatus(task.status)"
              color="blue20"
              :status="task.status"
              :can-update="canUpdate"
              @change-status="changeStatus({ newStatus: $event, id: task.id })"
              @delete="deleteTask(task.id)"
              @edit="editTask({ id: task.id })"
            />
          </v-col>
        </template>

        <h3 class="tasks-mobile-list__title">
          {{ task.name }}
        </h3>
        <base-row-field-table
          v-if="isRecurrenceTask(task.status)"
          :label="$t('task.date_first_task')"
          :text="task.issueDateFirst"
        />
        <base-row-field-table
          v-if="isRecurrenceTask(task.status)"
          :label="$t('task.date_last_task')"
          :text="task.issueDateLast"
        />
        <base-row-field-table :label="$t('label.deadline')" :text="task.deadline ? formatDate(task.deadline) : '—'" />
        <base-row-field-table :label="$t('label.roles')" :text="joinWithLimit(task.roles) || '—'" />
        <div class="tasks-mobile-list__container-line">
          <div class="tasks-mobile-list__line"></div>
        </div>
        <base-row-field-table :label="$t('label.client')" :text="task.client || ''" />
        <base-row-field-table :label="$t('label.project')" :text="task.project || '—'" />
        <base-row-field-table :label="$t('label.building')" :text="task.building || '—'" />
        <base-row-field-table :label="$t('label.unit')" :text="task.unit || '—'" />
        <base-row-field-table :label="$t('label.room')" :text="task.room || '—'" />
        <task-mobile-status-buttons
          v-if="showTaskButtons(task.forMe, task.status)"
          :status="task.status"
          :task-id="task.id"
          @change-status="changeStatus"
        />
      </filled-mobile-list>
    </div>
  </div>
</template>

<script>
import FilledMobileList from '@/components/FilledMobileList.vue';
import ViewListField from '@/components/ViewListField.vue';
import BaseRowFieldTable from '@/components/BaseRowFieldTable.vue';
import TaskMenu from '@/components/Tasks/TaskMenu.vue';

import { translateDate } from '@/utils/dateFormatting';
import joinWithLimit from '@/utils/joinWithLimit';
import format from 'date-fns/format';

import * as taskStatuses from '@/constants/taskStatuses';
import TaskMobileStatusButtons from '@/components/Tasks/TaskMobileStatusButtons.vue';

export default {
  name: 'TasksMobileList',
  components: { FilledMobileList, ViewListField, TaskMenu, BaseRowFieldTable, TaskMobileStatusButtons },
  props: {
    tasks: { type: Array, required: true },
    canUpdate: { type: Boolean, default: false },
  },
  computed: {
    bgChip() {
      return 'rgba(255, 255, 255, 0.8)';
    },
  },
  methods: {
    formatDate(date) {
      const noFormatDate = new Date(date);
      return translateDate(format(noFormatDate, 'd L yyyy'));
    },
    getClassChipStatus(status) {
      const BASE_CLASS = 'tasks__status';

      return {
        [`${BASE_CLASS}--pending`]: status === taskStatuses.PENDING,
        [`${BASE_CLASS}--in-progress`]: status === taskStatuses.IN_PROGRESS,
        [`${BASE_CLASS}--canceled`]: status === taskStatuses.CANCELED,
      };
    },
    showTaskButtons(forMe, status) {
      return forMe && status !== taskStatuses.CANCELED && status !== taskStatuses.COMPLETED && !this.canUpdate;
    },
    showAcceptButton(status) {
      return status === taskStatuses.PENDING;
    },
    isCanceledOrCompletedStatus(status) {
      return status === taskStatuses.CANCELED || status === taskStatuses.COMPLETED;
    },
    changeStatus({ newStatus, id }) {
      this.$emit('change-status', { newStatus, id });
    },
    isRecurrenceTask(status) {
      return !status;
    },
    deleteTask(id) {
      this.$emit('delete', id);
    },
    editTask(id) {
      this.$emit('edit', id);
    },
    openDetailed(id) {
      this.$emit('open-detailed', { id });
    },
    joinWithLimit,
  },
};
</script>

<style lang="scss">
.tasks-mobile-list {
  &__container-line {
    height: 34px;
    width: calc(100% + 16px);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  &__line {
    border-bottom: 1px solid #fff;
    width: 100%;
    position: absolute;
    right: 0;
    left: -8px;
  }
  &__title {
    font-weight: $--font-weight-medium;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 16px;
  }
}
</style>
