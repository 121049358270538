<!-- eslint-disable vue/no-v-html -->
<template>
  <view-field class="font-weight-medium" :label="label">
    <span v-html="currentValue"></span>
  </view-field>
</template>

<script>
import ViewField from '@/components/ViewField.vue';

export default {
  name: 'SchemaViewFieldString',

  components: { ViewField },

  props: {
    value: { type: String, default: '' },
    label: { type: String, required: true },
  },

  computed: {
    currentValue() {
      return this.value ? this.value.replace(/\n/g, '<br>') || '—' : this.value;
    },
  },
};
</script>
