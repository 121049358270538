import { ENUM, LIST } from '@/constants/viewTypes';

import * as comparators from '@/constants/comparators';
import { PATH_POINTERS } from '@/constants/builder';
import * as schemaItemTypes from './schemaItemTypes';
import * as dictionaryTypes from './dictionaryTypes';
import { rules } from './validationRules';

/**
 * @type {Array<import('./schema').default>}
 */
export const schema = [
  {
    label: 'label.information',
    type: schemaItemTypes.TITLE,
  },
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        type: schemaItemTypes.STRING,
        label: 'label.title',
        prop: 'name',
        rules: [rules.REQUIRED],
      },
      {
        type: schemaItemTypes.NUMBER,
        label: 'label.initial_cost',
        prop: 'initialAmount',
        rules: [rules.REQUIRED],
        appendText: 'invoice.euro',
        defaultValue: null,
      },
    ],
  },
  {
    type: schemaItemTypes.STRING,
    label: 'label.short_description',
    prop: 'shortDescription',
    rules: [rules.REQUIRED],
  },
  {
    type: schemaItemTypes.STRING,
    label: 'label.description',
    prop: 'description',
    textarea: true,
    rules: [rules.REQUIRED],
  },
  {
    type: schemaItemTypes.CONTRACTOR,
    label: 'label.contractor',
    prop: 'contractor',
    rules: [rules.REQUIRED],
  },
  {
    type: schemaItemTypes.MANY_PROJECTS,
    label: 'label.projects',
    prop: 'accessibleForProjects',
    rules: [rules.MANY_REQUIRED],
  },
  {
    type: schemaItemTypes.CATEGORY,
    label: 'label.category',
    prop: 'category',
  },
  { type: schemaItemTypes.NUMBER, label: 'label.order', prop: 'order' },
  {
    type: schemaItemTypes.BOOLEAN,
    prop: 'publicAccess',
    label: 'label.public_access',
  },
  {
    label: 'label.images',
    type: schemaItemTypes.TITLE,
  },
  {
    type: schemaItemTypes.IMAGE_LIST,
    prop: 'imageForList',
    label: 'label.load_image_for_list_(1:3)',
    selectable: true,
    editable: true,
    rules: [rules.REQUIRED],
  },

  {
    type: schemaItemTypes.IMAGE_LIST,
    prop: 'imageForRetrieve',
    label: 'label.load_image_for_information_(16:11)',
    selectable: true,
    editable: true,
    rules: [rules.REQUIRED],
  },

  {
    type: schemaItemTypes.SCRIPT_LIST,
    prop: 'scripts',
    rules: [rules.REQUIRED],
    viewType: LIST.counter,
    uniqueKey: 'id',
    addButtonText: 'button.add_scenario',
    label: 'label.scenario',
    min: 1,
    max: 100,
    children: [
      {
        type: schemaItemTypes.ENUM,
        dictionary: dictionaryTypes.SERVICES_SCENARIO_TYPES,
        viewType: ENUM.radio,
        prop: 'type',
        rules: [rules.REQUIRED],
      },
      {
        type: schemaItemTypes.STRING,
        label: 'label.link_name',
        prop: 'nameLink',
        rules: [rules.REQUIRED],
        scriptDisplayConditions: {
          variables: [
            {
              from: ['scripts', PATH_POINTERS.currentIndex, 'type'],
              comparableValues: ['link'],
              comparator: comparators.STRICT_COMPARE,
            },
          ],
          expression: `${'type'}`,
        },
      },
      {
        type: schemaItemTypes.STRING,
        label: 'label.link',
        prop: 'actionLink',
        rules: [rules.REQUIRED, rules.URL],
        scriptDisplayConditions: {
          variables: [
            {
              from: ['scripts', PATH_POINTERS.currentIndex, 'type'],
              comparableValues: ['link'],
              comparator: comparators.STRICT_COMPARE,
            },
          ],
          expression: `${'type'}`,
        },
      },
      {
        type: schemaItemTypes.STRING,
        label: 'label.message_title',
        prop: 'nameMessage',
        rules: [rules.REQUIRED],
        scriptDisplayConditions: {
          variables: [
            {
              from: ['scripts', PATH_POINTERS.currentIndex, 'type'],
              comparableValues: ['quick_chat_message'],
              comparator: comparators.STRICT_COMPARE,
            },
          ],
          expression: `${'type'}`,
        },
      },
      {
        type: schemaItemTypes.STRING,
        label: 'label.text',
        prop: 'actionMessage',
        rules: [rules.REQUIRED],
        scriptDisplayConditions: {
          variables: [
            {
              from: ['scripts', PATH_POINTERS.currentIndex, 'type'],
              comparableValues: ['quick_chat_message'],
              comparator: comparators.STRICT_COMPARE,
            },
          ],
          expression: `${'type'}`,
        },
      },
      {
        type: schemaItemTypes.STRING,
        label: 'label.file_name',
        prop: 'nameFile',
        rules: [rules.REQUIRED],
        scriptDisplayConditions: {
          variables: [
            {
              from: ['scripts', PATH_POINTERS.currentIndex, 'type'],
              comparableValues: ['file'],
              comparator: comparators.STRICT_COMPARE,
            },
          ],
          expression: `${'type'}`,
        },
      },
      {
        label: 'label.load_pdf',
        prop: 'actionFile',
        type: schemaItemTypes.FILE_LIST,
        rules: [rules.ALL_LOADED, rules.REQUIRED],
        editable: true,
        selectable: true,
        accept: 'application/pdf',
        showRow: true,
        scriptDisplayConditions: {
          variables: [
            {
              from: ['scripts', PATH_POINTERS.currentIndex, 'type'],
              comparableValues: ['file'],
              comparator: comparators.STRICT_COMPARE,
            },
          ],
          expression: `${'type'}`,
        },
      },
    ],
  },
];

export { schema as default };
