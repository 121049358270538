<!-- eslint-disable vue/no-v-html -->
<template>
  <v-container class="announcement-detailed pa-0" fluid>
    <base-loader v-if="isAnnouncementLoading" />

    <div class="d-flex align-center mb-4 mb-md-10">
      <icon-button color="secondary" :to="{ name: $options.ANNOUNCEMENTS_SENT }" exact>
        <v-icon>mdi-arrow-left</v-icon>
      </icon-button>

      <h1 class="text-h5 text-md-h4 font-weight-bold ml-2 ml-md-6">{{ $t('announcements.announcement') }}</h1>
    </div>

    <div class="announcement-detailed__image-container">
      <v-img :src="image" :aspect-ratio="aspectRatio"></v-img>
      <div class="announcement-detailed__date-container text-caption text-md-body-2 font-weight-medium pa-2">
        <span class="announcement-detailed__time font-weight-regular">{{ normalizedTime }}</span>
        {{ normalizedDate }}
      </div>
    </div>

    <article class="mt-2 mt-md-4">
      <h2 class="text-h6 text-md-h4 font-weight-medium mb-1 mb-md-2">{{ announcement.title }}</h2>

      <div v-if="recipients" class="announcement-detailed__recipients mb-4 text-caption text--secondary">
        {{ recipients }}
      </div>

      <p class="announcement-detailed__content text-body-2 mb-5" v-html="announcement.content"></p>
    </article>
  </v-container>
</template>

<script>
// node_modules
import { format } from 'date-fns';

// Utils
import { translateDate } from '@/utils/dateFormatting';
import { scrollToTop } from '@/utils/scroll';

// Constants
import { ANNOUNCEMENTS_SENT } from '@/constants/routes';
import { NEWS } from '@/constants/analyticsActions';
import { MANAGEMENT_COMPANY_EMPLOYEE } from '@/constants/roles';

// Components
import IconButton from '@/components/IconButton.vue';
import BaseLoader from '@/components/BaseLoader.vue';

// Services
import announcementService from '@/services/announcement';
import analyticsService from '@/services/analytics';

export default {
  name: 'AnnouncementsDetailed',

  components: { IconButton, BaseLoader },

  props: {
    id: { type: Number, required: true },
  },

  inject: ['media'],

  data() {
    return {
      announcement: {},
      isAnnouncementLoading: false,
    };
  },

  computed: {
    image() {
      return this.announcement.image?.url || this.announcement.defaultImage?.url;
    },

    aspectRatio() {
      if (this.media.isMobile) {
        return 38 / 15;
      }

      return 548 / 285;
    },

    recipients() {
      console.log(this.announcement?.clients);
      if (this.announcement?.clients?.all) {
        return this.$t('label.all_clients');
      }
      return this.announcement?.clients?.include?.map(client => `${client.firstName} ${client.lastName}`).join(', ');
    },

    date() {
      if (!this.announcement?.dispatchTime) return new Date();
      return new Date(this.announcement.dispatchTime);
    },

    normalizedDate() {
      return translateDate(format(this.date, 'd L yyyy'));
    },

    normalizedTime() {
      return format(this.date, 'HH:mm');
    },

    isEmployee() {
      return this.$store.state.user.role === MANAGEMENT_COMPANY_EMPLOYEE;
    },
  },

  mounted() {
    analyticsService.track(NEWS);
    // Fix: mobile edge scroll to top after open news page
    scrollToTop();

    this.isAnnouncementLoading = true;

    const request = this.isEmployee ? announcementService.getById : announcementService.getForClientById;

    request(this.id)
      .then(announcement => {
        this.announcement = {
          ...announcement,
          content: announcement.content
            .replace(/\n/g, '<br>')
            .replace(/href/gi, 'target="_blank" rel="noopener noreferrer" href'),
        };
      })
      .finally(() => {
        this.isAnnouncementLoading = false;
      });
  },

  ANNOUNCEMENTS_SENT,
};
</script>

<style lang="scss">
$header-height: 80px;

.announcement-detailed {
  position: relative;
  max-width: 548px !important;

  &__content {
    opacity: 0.65;
    white-space: pre-wrap;
  }

  &__image-container {
    position: relative;
  }

  &__date-container {
    position: absolute;
    top: 0;
    left: 0;
    background: $--white-color-0;
    border-radius: 0 0 4px 0;
  }

  &__recipients {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
</style>
