<template>
  <form class="clients-filters">
    <schema-form-field-date
      v-if="isShortTenantsType"
      v-model="filter.date"
      multiple
      clearable
      :label="$t('clients.period')"
      @input="inputCheckbox"
    />
    <v-checkbox
      v-model="filter.statuses"
      value="active"
      multiple
      dense
      hide-details
      :label="$t('clients.active')"
      class="clients-filters__checkbox clients-filters__checkbox_first"
      @change="inputCheckbox"
    ></v-checkbox>
    <v-checkbox
      v-model="filter.statuses"
      value="archived"
      multiple
      dense
      hide-details
      :label="$t('clients.archive')"
      class="clients-filters__checkbox"
      @change="inputCheckbox"
    ></v-checkbox>
    <v-checkbox
      v-model="filter.statuses"
      value="unconfirmed"
      multiple
      dense
      hide-details
      :label="$t('clients.unconfirmed')"
      class="clients-filters__checkbox"
      @change="inputCheckbox"
    ></v-checkbox>
    <v-checkbox
      v-model="filter.statuses"
      value="invited"
      multiple
      dense
      hide-details
      :label="$t('clients.invited')"
      class="clients-filters__checkbox"
      @change="inputCheckbox"
    ></v-checkbox>
  </form>
</template>

<script>
import SchemaFormFieldDate from '@/components/schema/FormFieldDate.vue';
import * as typesClient from '@/constants/typesClients';
import { clone } from '@/utils/clone';

export default {
  name: 'ClientsFilters',
  components: {
    SchemaFormFieldDate,
  },
  props: {
    value: { type: Object, required: true },
    typeClient: { type: String, required: true },
  },
  data() {
    return {
      filter: {
        date: null,
      },
    };
  },
  computed: {
    isShortTenantsType() {
      return this.typeClient === typesClient.SHORT_TENANTS;
    },
  },
  mounted() {
    this.filter = clone(this.value);
  },
  methods: {
    inputCheckbox() {
      this.$emit('input', this.filter);
    },
  },
};
</script>

<style lang="scss">
.clients-filters {
  display: flex;
  align-items: center;
  margin-left: 47px;

  @media (max-width: map.get($--screens, 'sm')) {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0;
  }

  &__checkbox {
    margin-right: 27px;
    margin-bottom: 20px;
    @media (max-width: map.get($--screens, 'sm')) {
      margin-bottom: 0;
    }
    &_first {
      margin-left: 25px;
      @media (max-width: map.get($--screens, 'sm')) {
        margin-left: 0;
      }
    }
  }

  .v-input__slot {
    min-height: 54px;
    @media (max-width: map.get($--screens, 'sm')) {
      min-height: 40px;
    }
  }
  .v-input__append-inner {
    align-items: center;
    height: 40px;
    @media (max-width: map.get($--screens, 'sm')) {
      height: auto;
    }
  }
  .v-input__prepend-inner {
    align-items: center;
    height: 40px;
    @media (max-width: map.get($--screens, 'sm')) {
      height: auto;
    }
  }
}
</style>
