<template>
  <v-container class="invoices-create" fluid>
    <entity-modify-header class="mb-10" :title="$t('invoice.issue_invoice')" />

    <form-builder v-if="!isLoading" :schema="$options.schema" :initial-data="initialData" @submit="submit">
      <template #footer="{ valid }">
        <v-row class="mt-10">
          <v-col>
            <v-btn color="secondary" block class="primary--text" :to="backRoute">{{ $t('button.back') }}</v-btn>
          </v-col>
          <v-col>
            <v-btn type="submit" color="primary" block :disabled="!valid">{{ $t('button.send_invoice') }}</v-btn>
          </v-col>
        </v-row>
      </template>
    </form-builder>
  </v-container>
</template>

<script>
// Models
import { schema } from '@/schemas/invoice.schema';

// Utils
import { createModelData } from '@/schemas/createModelData';

// Components
import FormBuilder from '@/components/schema/FormBuilder.vue';
import EntityModifyHeader from '@/components/EntityModifyHeader.vue';

// Constants
import { INVOICES_UNPAID } from '@/constants/routes';
import { CREATE_INVOCE } from '@/constants/analyticsActions';

// Services
import paymentsService from '@/services/payments';
import notificationService from '@/services/notification';
import companyService from '@/services/company';
import analyticsService from '@/services/analytics';

export default {
  name: 'InvoicesCreate',

  components: { FormBuilder, EntityModifyHeader },

  data() {
    return {
      isLoading: false,
      initialData: createModelData(schema),
    };
  },

  computed: {
    backRoute() {
      return { name: INVOICES_UNPAID, query: { page: +this.$route.query.prevPage || 1 } };
    },
  },

  async created() {
    this.isLoading = true;

    try {
      const automaticInvoiceNumberingIsEnable = (await companyService.getSettingsFlag()).automaticInvoiceNumbering;

      this.initialData.automaticInvoiceNumbering = automaticInvoiceNumberingIsEnable;
      // this.initialData.automaticInvoiceNumberingTooltip = automaticInvoiceNumberingIsEnable
      //   ? 'invoice.automatic_invoice_numbering_tooltip'
      //   : undefined;
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    submit(data) {
      if (this.$options.notificationItem) {
        notificationService.remove(this.$options.notificationItem);
      }
      /* eslint-disable prefer-destructuring */
      /* eslint-disable no-param-reassign */
      /* eslint-disable no-self-assign */
      if (data.period) {
        data.period = data.period;
      } else if (data.customPeriod) {
        data.period = data.customPeriod.split(' - ')[0];
      }

      const totalAmount = data.services.reduce((total, service) => {
        const amountWithVat = Number(service.amount + service.amount * (service.vat / 100));
        return total + Number(amountWithVat);
      }, 0);

      if (totalAmount < 0) {
        this.$options.notificationItem = notificationService.error(this.$t('error.positive_total_amount'));
        return;
      }

      const fetchData = {
        ...data,
        serviceType: data.serviceType.value,
        clientId: data.client.id,
        unit: data.room?.id || data.unit.id,
        contractor: data.contractor.id,
        periodEnd: data.customPeriod ? data.customPeriod.split(' - ')[1] : null,
        isOneTime: data.periodType !== 'month',
      };

      analyticsService.track(CREATE_INVOCE);
      paymentsService
        .createInvoice(fetchData)
        .then(() => {
          notificationService.success(this.$t('invoice.invoice_created'), 2000);
          this.$router.push(this.backRoute);
        })
        .catch(error => {
          this.$options.notificationItem = notificationService.error(
            `${this.$t('error.found_errors')} ${Object.keys(error?.response?.data).join(', ')}`
          );
        });
    },
  },

  notificationItem: null,
  schema,
};
</script>

<style lang="scss">
.invoices-create {
  max-width: 548px !important;
}
</style>
