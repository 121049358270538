import * as subjects from '@/constants/subjects';
import * as types from './types';

function getAccessRights(allRights = false) {
  return {
    read: !!allRights,
    write: !!allRights,
  };
}

const SUBJECTS_MAP = {
  announcements: subjects.ANNOUNCEMENTS,
  chat: subjects.CHAT,
  contractors: subjects.CONTRACTORS,
  invoices: subjects.INVOICES,
  projects: subjects.PROJECTS,
  assets: subjects.ASSETS,
  clients: subjects.CLIENTS,
  employees: subjects.EMPLOYEES,
  dashboard: subjects.DASHBOARD,
  contracts: subjects.CONTRACTS,
  issues: subjects.ISSUES,
  services: subjects.SERVICES,
  requests: subjects.REQUESTS,
  docuseal: subjects.DOCUSEAL,
  rooms: subjects.ROOMS,
  calendar: subjects.CALENDAR,
};

function getSubjectForSectionType(sectionType) {
  const result = SUBJECTS_MAP[sectionType];

  if (!result) {
    console.warn('User access right unknown');
  }

  return result;
}

export default {
  [types.RIGHTS](state) {
    const rights = {};
    const sections = state.user?.sections;
    const companySections = state.user?.companySections;

    if (!sections) return [];

    sections.forEach(section => {
      const subject = getSubjectForSectionType(section.name);

      if (!rights[subject]) {
        rights[subject] = getAccessRights();
      }

      rights[subject][section.type] = true;
    });

    companySections.forEach(section => {
      const subject = getSubjectForSectionType(section.name);

      if (!subject) return;

      if (subject === 'docuseal') {
        rights[subject] = getAccessRights(true);
      }
    });

    return rights;
  },
  [types.COMPANY_SECTIONS_RIGHTS](state) {
    return state.user?.companySections;
  },
};
