<template>
  <v-select
    v-model="modelWrapper"
    :items="selectionOptions"
    :multiple="multiple"
    :clearable="!multiple"
    :menu-props="{ top: false, offsetY: true }"
    :label="label"
    v-on="inheritedListeners"
  />
</template>

<script>
// Constants
import { createModelWrapper, getInheritedListeners } from '@/utils/components';

// Models
import { UNIQUE_KEY } from '@/schemas/config';

export default {
  UNIQUE_KEY,

  name: 'SchemaFormFieldRequestFormList',

  inject: {
    addPropertyValueItem: 'addPropertyValueItem',
    deletePropertyValueItem: 'deletePropertyValueItem',
    filterSchema: 'filterSchema',
  },

  inheritAttrs: false,

  props: {
    value: {
      type: [String, Array],
      default: () => [],
    },
    selectionOptions: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    modelWrapper: createModelWrapper('value', 'input'),
    inheritedListeners: getInheritedListeners(['input']),
  },
};
</script>
