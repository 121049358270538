<template>
  <time-picker
    v-model="modelWrapper"
    :is-today="payload.isToday"
    :label="label"
    :minute-interval="minuteInterval"
    :clearable="clearable"
    v-on="inheritedListeners"
  />
</template>

<script>
// node_modules
import { format, setMinutes } from 'date-fns';

// Utils
import { createModelWrapper, getInheritedListeners } from '@/utils/components';

// Components
import TimePicker from '@/components/TimePicker.vue';
import { roundMinutes } from '@/utils/dateFormatting';

export default {
  name: 'FormFieldTime',

  components: { TimePicker },

  props: {
    value: { type: String, default: '' },
    payload: { type: Object, default: () => ({}) },
    minuteInterval: { type: Number, default: 1 },
    label: { type: String, default: '' },
    clearable: { type: Boolean, default: false },
  },

  computed: {
    modelWrapper: createModelWrapper('value', 'input'),
    inheritedListeners: getInheritedListeners(['input']),
  },

  watch: {
    'payload.isToday': {
      handler(newValue, oldValue) {
        if (newValue === oldValue || !newValue) return;

        const nowDate = new Date();

        const minutes = roundMinutes(nowDate.getMinutes(), this.minuteInterval);
        const resultDate = setMinutes(nowDate, minutes);

        this.modelWrapper = format(resultDate, 'HH:mm');
      },
    },
  },
};
</script>
